import React, { useEffect, useState } from 'react';

import FormBox from 'components/FormBox';
import PageTitle from 'components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import Form from './form';
import Resume from './resume';
import Evidencia from 'services/Evidencia';
import EvidenciaEventos from 'services/EvidenciaEventos';
import jsonToFormData from 'helpers/jsonBuildFormData';
import Rascunho from 'services/Rascunho';
import { getDateFromStringJson } from 'helpers/Date';

function Eventos() {
  const navigate = useNavigate();
  const { id, rascunho } = useParams();

  const [activeTab, setActiveTab] = useState({
    pagina: 'form',
    subTitle: 'Preencha os dados abaixo e inclua as mídias:'
  });
  const [formData, setFormData] = useState({
    evidenciaId: null,
    redeId: null,
    rede: '',
    investimento: '',
    redeParceiro: '',
    marcasPositivadas: [],
    inicio: '',
    fim: '',
    resultados: '',
    arquivos: [{ id: 0 }]
  });
  const [errors, setErrors] = useState([]);
  const [marcasList, setMarcasList] = useState([]);

  const [validacaoCampos, setValidacaoCampos] = useState({
    rede: true,
    investimento: true,
    marcasPositivadas: true
  });

  useEffect(() => {
    (async () => {
      window.showLoader();
      if (!formData.evidenciaId) {
        //edit
        if (id > 0) {
          if (rascunho === 'rascunho') {
            let { value } = await Rascunho.GetRascunho(id);
            value = JSON.parse(value.rascunho);
            if (value.arquivos && value.arquivos.length > formData.arquivos.length) {
              while (formData.arquivos.length < value.arquivos.length) {
                const index = formData.arquivos.length;
                formData.arquivos.push({ id: index });
              }
            }
            setFormData({
              ...formData,
              ...value,
              updateFileIdCounter: true,
              arquivos: formData.arquivos.map((arquivo, index) => ({
                ...arquivo,
                ...value.arquivos[index]
              })),
              inicio: getDateFromStringJson(value.inicio),
              fim: getDateFromStringJson(value.fim)
            });
            setValidacaoCampos({
              rede: value.redeId ? true : false,
              investimento: value.investimento ? true : false,
              marcasPositivadas: value.marcasPositivadas.length ? true : false
            });
          } else {
            const { value } = await EvidenciaEventos.edit(id);
            if (value.arquivos && value.arquivos.length > formData.arquivos.length) {
              while (formData.arquivos.length < value.arquivos.length) {
                const index = formData.arquivos.length;
                formData.arquivos.push({ id: index });
              }
            }
            setFormData({
              ...formData,
              ...value,
              updateFileIdCounter: true,
              arquivos: formData.arquivos.map((arquivo, index) => ({
                ...arquivo,
                ...value.arquivos[index]
              })),
              inicio: getDateFromStringJson(value.inicio),
              fim: getDateFromStringJson(value.fim)
            });
            setValidacaoCampos({
              rede: value.redeId ? true : false,
              investimento: value.investimento ? true : false,
              marcasPositivadas: value.marcasPositivadas.length ? true : false
            });
          }
        }
        //create
        else {
          const { value } = await EvidenciaEventos.create();
          setFormData({ ...formData, evidenciaId: value.evidenciaId });
        }
      }

      let { value: marcas } = await Evidencia.GetListMarca();
      marcas = marcas.map((item) => {
        return { label: item.nome, value: item.produtoMarcaId };
      });
      setMarcasList(marcas);
      window.hideLoader();
    })();
  }, []);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const saveForm = async () => {
    window.showLoader();
    const formRequest = jsonToFormData(formData);
    const data = await EvidenciaEventos.save(formRequest);
    if (data.value) {
      window.hideLoader();
      navigate('/sucesso');
    } else {
      window.scrollTo(0, 0);
      setErrors(data.errors);
      window.hideLoader();
    }
  };

  const renderTab = () => {
    let contentNow = <h1>Página não encontrada</h1>;
    if (activeTab.pagina === 'form') {
      contentNow = (
        <Form
          formData={formData}
          setFormData={setFormData}
          nextTab={() => changeTab({ pagina: 'resume', subTitle: 'Confira todos os dados abaixo' })}
          validacaoCampos={validacaoCampos}
          setValidacaoCampos={setValidacaoCampos}
          marcasList={marcasList}
        />
      );
    }
    if (activeTab.pagina === 'resume') {
      contentNow = (
        <Resume
          backTab={() =>
            changeTab({ pagina: 'form', subTitle: 'Preencha os dados abaixo e inclua as mídias:' })
          }
          saveForm={saveForm}
          formData={formData}
          marcasList={marcasList}
        />
      );
    }
    return contentNow;
  };

  return (
    <div className="eventos">
      <PageTitle subTitle="Preencha os dados abaixo e inclua as mídias:">EVENTOS</PageTitle>
      <FormBox>
        <div className="row">
          {errors && errors.length !== 0 && (
            <div className="alert alert-danger">
              Erro ao realizar o envio, por favor confira os dados e tente novamente.
            </div>
          )}

          <div className="row">{renderTab()}</div>
        </div>
      </FormBox>
    </div>
  );
}

export default Eventos;
