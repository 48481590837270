import { useState, useEffect } from 'react';

import Login from 'services/Login';

const useAuth = () => {
  const [userData, setUserData] = useState({
    jwtToken: null,
    userId: null,
    authenticated: false,
    nome: null,
    regionalId: null,
    urlRedirect: null,
    errors: null
  });
  const [errorNetwork, setErrorNetwork] = useState(false);

  useEffect(() => {
    async function autoLogin() {
      // const loginUserData = JSON.parse(localStorage.getItem('midias'));
      let tokenOnOff = '';
      let data = null;
      // eslint-disable-next-line no-undef
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // development build code
        // Local
        tokenOnOff = '1436376e-7f82-472f-ab92-81521147ebaf';
        data = await Login.LoginWithTokenTeste(tokenOnOff);
      } else {
        // production build code
        data = await Login.GetUser();
      }

      setUserData({
        jwtToken: data.jwtToken,
        ...data.value
      });
      setLocalStorage(data);
    }
    autoLogin();
  }, []);

  function setLocalStorage(data) {
    localStorage.setItem('ativacoescanalas', JSON.stringify(data));
    localStorage.setItem('ativacoescanalas_token', data.jwtToken);
  }

  return {
    userData,
    setUserData,
    errorNetwork,
    setErrorNetwork
  };
};

export default useAuth;
