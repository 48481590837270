import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import Evidencia from 'services/Evidencia';
import SemFoto from 'assets/imgs/SEMFOTO.png';

import './styles.scss';

const SearchRedeSimples = ({ onChange, filtro, setFiltro }) => {
  const [show, setShow] = useState(false);
  const [redes, setRedes] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState({});

  const changeNewRede = () => {
    //if (redeSelecionada.redeId) {
    setFiltro(redeSelecionada?.nome ?? '');
    onChange(redeSelecionada);
    //}
    handleClose();
  };

  const handleShow = async () => {
    window.showLoader();
    const data = await Evidencia.GetListRede(filtro);
    setRedes(data.value);
    setShow(true);
    window.hideLoader();
  };

  const handleClose = () => setShow(false);

  const renderModal = () => {
    return (
      <Modal show={show} onHide={handleClose} className="rede-modal">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>Selecione a rede e clique em confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row border border-1 p-2 my-1 modal-rede-list">
            {/* {renderRedes()} */}
            {redes.length
              ? redes.map((item) => {
                  return (
                    <div
                      className={`col-12 ${
                        redeSelecionada.redeId === item.redeId && 'item-selecionado'
                      }`}
                      key={item.redeId}
                      onClick={() =>
                        setRedeSelecionada(redeSelecionada.redeId !== item.redeId ? item : {})
                      }>
                      <div className="row py-2 rede-item">
                        <div className="col-4">
                          <img
                            src={item.urlLogo ? item.urlLogo : SemFoto}
                            alt={item.nome}
                            className="w-100"
                          />
                        </div>
                        <div className="col-4 d-flex justify-content-start align-items-center ps-4">
                          {item.nome}
                        </div>
                        <div className="col-4 d-flex justify-content-start align-items-center ps-4">
                          {item.nome != item.bandeira ? item.bandeira : ''}
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="ms-2 p-2 fw-semibold" width="320px" onClick={() => changeNewRede()}>
            CONFIRMAR
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <div className="search-rede-simples">
        <label className="form-label">Rede</label>
        <div className="input-group mb-3">
          <input
            type="text"
            placeholder="Nome da Rede"
            className="form-control"
            style={{ minHeight: '47.6px' }}
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
          />
          <button type="button" className="btn btn-primary px-3" onClick={handleShow}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default SearchRedeSimples;
