import getCampoObrigatorioClassName from 'helpers/campoObrigatorio';
import React from 'react';
import CurrencyInput from 'react-currency-input';

const InputCurrency = ({
  label,
  onChange,
  value,
  campoObrigatorio = false,
  disabled = false,
  className = ''
}) => {
  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <div className={`input-group`}>
        <span
          className={`input-group-text fw-bolder bg-white ${
            campoObrigatorio && getCampoObrigatorioClassName(value)
          }`}>
          R$
        </span>

        <CurrencyInput
          decimalSeparator=","
          thousandSeparator="."
          // className="form-control border-start-0"
          className={`form-control border-start-0 ${className} ${
            campoObrigatorio && getCampoObrigatorioClassName(value)
          }`}
          onChangeEvent={(event, maskedValue, floatValue) => {
            return onChange(floatValue);
          }}
          value={value}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default InputCurrency;
