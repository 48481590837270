import React, { createContext } from 'react';
import useAuth from './AuthContext';
import { Outlet } from 'react-router-dom';

const Context = createContext();

const GlobalProvider = () => {
  const { handleLogin, handleLogout, userData, setUserData, loginWithToken } = useAuth();

  return (
    <>
      <Context.Provider
        value={{
          handleLogin,
          handleLogout,
          userData,
          setUserData,
          loginWithToken
        }}>
        <Outlet />
      </Context.Provider>
    </>
  );
};

export { Context, GlobalProvider };
