import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRoute from 'routes';
import Loading from 'components/Loading';

import './themes/theme.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Loading />
    <AppRoute />
  </>
);
