import React, { useState, useEffect } from 'react';
import SelectInput from 'components/SelectInput';
import MyDatePicker from 'components/DatePicker';
import SearchRedeSimples from 'components/SearchRedeSimples';
import Button from 'components/Button';

import Evidencia from 'services/Evidencia';

import './style.scss';

const FiltroEvidencias = ({ onFiltrar }) => {
  const [pilarId, setPilarId] = useState(0);
  const [pilares, setPilares] = useState([]);
  const [dataEnvio, setDataEnvio] = useState('');
  const [rede, setRede] = useState({});
  const [filtroRede, setFiltroRede] = useState('');

  useEffect(() => {
    window.showLoader();
    getPilares();
    setPilarId(0);
    setDataEnvio('');
    setRede({});
    window.hideLoader();
  }, []);

  const getPilares = async () => {
    const res = await Evidencia.GetListPilar();
    let pilarList = res?.value?.map((item) => {
      return { value: item.evidenciaPilarId, label: item.nome };
    });
    setPilares(pilarList ?? []);
  };

  const onChangePilar = (value) => {
    setPilarId(value);
  };

  const onFiltrarClick = () => {
    onFiltrar(dataEnvio, pilarId, rede);
  };

  const onLimparClick = () => {
    setPilarId(0);
    setDataEnvio('');
    setRede({});
    setFiltroRede('');
  };

  return (
    <div className="filtro-evidencias">
      <div className="row">
        <div className="col-4">
          <SelectInput
            className={'input-pilar'}
            label="Pilar de Ação"
            isClearable={true}
            options={pilares}
            value={pilarId}
            onChange={onChangePilar}></SelectInput>
        </div>
        <div className="col-4">
          <MyDatePicker
            label="Data"
            selected={dataEnvio || ''}
            onChange={(value) => setDataEnvio(value)}
          />
        </div>
        <div className="col-4">
          <SearchRedeSimples onChange={setRede} filtro={filtroRede} setFiltro={setFiltroRede} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <Button onClick={onFiltrarClick}>Filtrar Evidências</Button>
          <Button onClick={onLimparClick} color="white">
            Limpar Filtro
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FiltroEvidencias;
