import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

const HomeButton = () => {
  return (
    <div className="homeButton">
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-3 py-1 d-flex align-items-center">
            <ul className="orange">
              <Link to="/ativacoes/acoes-lmpm" className="text-decoration-none">
                <li>LMPM / Combo</li>
              </Link>

              <Link to="/ativacoes/espaco" className="text-decoration-none">
                <li>Espaço</li>
              </Link>

              <Link to="/ativacoes/encartes" className="text-decoration-none">
                <li>Encartes</li>
              </Link>

              <Link to="/ativacoes/digital" className="text-decoration-none">
                <li>Digital</li>
              </Link>

              <Link to="/ativacoes/eventos" className="text-decoration-none">
                <li>Eventos</li>
              </Link>

              <Link to="/ativacoes/visibilidade" className="text-decoration-none">
                <li>Indoor / Visibilidade</li>
              </Link>

              {/* <Link to="/ativacoes/scanntech" className="text-decoration-none">
                <li>Scanntech</li>
              </Link> */}
            </ul>
          </div>
          <div className="col-6 col-md-3 text-center py-1">
            <div className="button-orange d-flex justify-content-center align-items-center">
              <h2>Ativações</h2>
            </div>
          </div>
          <div className="col-6 col-md-3 text-center py-1">
            <div className="button-blue d-flex justify-content-center align-items-center">
              <h2>
                Evidências
                <br />
                Extras
              </h2>
            </div>
          </div>
          <div className="col-6 col-md-3 py-1 d-flex align-items-center">
            <ul className={`blue`}>
              <Link to="/evidencias-extras" className="text-decoration-none">
                <li>Evidências Extras</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeButton;
