import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import React from 'react';
import { Table } from 'react-bootstrap';

import DisplayImageType from 'components/DisplayImageType';
import { dateFormat } from 'helpers/Date';
import { numberToStringCurrency } from 'helpers/money';

const Resume = ({ backTab, saveForm, formData, tipoArquivoList, categoriasPositivasList }) => {
  return (
    <>
      <Table striped hover>
        <tbody className="col-12">
          <tr>
            <td className="col-4">
              <b>Tipo de Ativação</b>
            </td>
            <td className="col-8">ESPAÇO</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Rede</b>
            </td>
            <td className="col-8">{formData.rede?.nome}</td>
          </tr>
          {formData.investimentoJBPRadio && (
            <tr>
              <td className="col-4">
                <b>Investimento Total</b>
              </td>
              <td className="col-8">Ativação contemplada no JBP</td>
            </tr>
          )}
        </tbody>

        {formData.pontoNatural.length !== 0 &&
          formData.pontoNatural.map((pontoNatural) => {
            return (
              <>
                <div className="pontoExtra-Resume mt-4">
                  <h5>PONTO NATURAL {pontoNatural.id}</h5>
                </div>
                <br />
                {pontoNatural.spreadsInicio && (
                  <div>
                    <h6>SPREADS</h6>
                  </div>
                )}
                <tbody>
                  {
                    // SPREADS
                  }
                  {pontoNatural.spreadsInvestimento !== 0 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.spreadsInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.spreadsInicio && pontoNatural.spreadsFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.spreadsInicio)} -{' '}
                        {dateFormat(pontoNatural.spreadsFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.spreadsQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.spreadsQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.spreadsQtdeEspacoNegociado && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Espaço Negociado (em metros)</b>
                      </td>
                      <td className="col-8">{pontoNatural.spreadsQtdeEspacoNegociado}</td>
                    </tr>
                  )}
                </tbody>

                {pontoNatural.friosInicio && (
                  <div>
                    <br />
                    <h6>FRIOS</h6>
                  </div>
                )}
                <tbody>
                  {
                    // Frios
                  }
                  {pontoNatural.friosInvestimento !== 0 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.friosInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.friosInicio && pontoNatural.friosFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.friosInicio)} - {dateFormat(pontoNatural.friosFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.friosQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.friosQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.friosQtdeEspacoNegociado && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Espaço Negociado (em metros)</b>
                      </td>
                      <td className="col-8">{pontoNatural.friosQtdeEspacoNegociado}</td>
                    </tr>
                  )}
                </tbody>

                {pontoNatural.congeladosInicio && (
                  <div>
                    <br />
                    <h6>CONGELADOS</h6>
                  </div>
                )}
                <tbody>
                  {
                    // CONGELADOS
                  }
                  {pontoNatural.congeladosInvestimento !== 0 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.congeladosInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.congeladosInicio && pontoNatural.congeladosFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.congeladosInicio)} -{' '}
                        {dateFormat(pontoNatural.congeladosFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.congeladosQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.congeladosQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.congeladosQtdeEspacoNegociado && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Espaço Negociado (em metros)</b>
                      </td>
                      <td className="col-8">{pontoNatural.congeladosQtdeEspacoNegociado}</td>
                    </tr>
                  )}
                </tbody>

                {pontoNatural.embutidosInicio && (
                  <div>
                    <br />
                    <h6>EMBUTIDOS</h6>
                  </div>
                )}
                <tbody>
                  {
                    // CONGELADOS
                  }
                  {pontoNatural.embutidosInvestimento !== 0 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.embutidosInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.embutidosInicio && pontoNatural.embutidosFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.embutidosInicio)} -{' '}
                        {dateFormat(pontoNatural.embutidosFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.embutidosQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.embutidosQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.embutidosQtdeEspacoNegociado && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Espaço Negociado (em metros)</b>
                      </td>
                      <td className="col-8">{pontoNatural.embutidosQtdeEspacoNegociado}</td>
                    </tr>
                  )}
                </tbody>

                {pontoNatural.inNaturaInicio && (
                  <div>
                    <br />
                    <h6>IN NATURA</h6>
                  </div>
                )}
                <tbody>
                  {
                    // CONGELADOS
                  }
                  {pontoNatural.inNaturaInvestimento !== 0 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.inNaturaInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.inNaturaInicio && pontoNatural.inNaturaFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.inNaturaInicio)} -{' '}
                        {dateFormat(pontoNatural.inNaturaFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.inNaturaQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.inNaturaQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.inNaturaQtdeEspacoNegociado && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Espaço Negociado (em metros)</b>
                      </td>
                      <td className="col-8">
                        {pontoNatural.inNaturaQtdeEspacoNegociado} <br />
                      </td>
                    </tr>
                  )}
                </tbody>
                <tr>
                  <td colSpan="100%">
                    <div className="row">
                      <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
                      <DisplayImageType
                        arquivos={pontoNatural.arquivos}
                        tipoArquivoList={tipoArquivoList}
                      />
                    </div>
                  </td>
                </tr>
              </>
            );
          })}

        {formData.pontoExtra.length !== 0 &&
          formData.pontoExtra.map((pontoExtra) => {
            return (
              <>
                <div className="pontoExtra-Resume mt-4">
                  <h5>PONTO EXTRA {pontoExtra.id}</h5>
                </div>
                <tbody>
                  {pontoExtra.inicio && pontoExtra.fim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoExtra.inicio)} - {dateFormat(pontoExtra.fim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoExtra.investimento !== -1 && (
                    <tr>
                      <td className="col-4">
                        <b>Investimento na Ação</b>
                      </td>
                      <td className="col-8">
                        {pontoExtra.investimento === -1
                          ? 'Ativação contemplada no JBP'
                          : numberToStringCurrency(pontoExtra.investimento)}
                      </td>
                    </tr>
                  )}
                  {pontoExtra.qtdeLojasTotal && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Total</b>
                      </td>
                      <td className="col-8">{pontoExtra.qtdeLojasTotal}</td>
                    </tr>
                  )}
                  {pontoExtra.qtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoExtra.qtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoExtra.qtdeTotalPontoExtra && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Total de Ponto Extra (em 100% das lojas)</b>
                      </td>
                      <td className="col-8">{pontoExtra.qtdeTotalPontoExtra}</td>
                    </tr>
                  )}
                </tbody>
                <tr>
                  <td colSpan="100%">
                    <div className="row">
                      <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
                      <DisplayImageType
                        arquivos={pontoExtra.arquivos}
                        tipoArquivoList={categoriasPositivasList}
                      />
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
      </Table>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
