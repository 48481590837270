import api from 'api';

const PATH = '/Rascunho';

class Rascunho {
  static async PostSalvarRascunho(bodyData, evidenciaId) {
    const { data } = await api.post(
      `${PATH}/salvar-rascunho?id=${evidenciaId}`,
      JSON.stringify(bodyData)
    );
    return data;
  }

  static async GetListRascunho(filtro) {
    const { data } = await api.get(`${PATH}/list-rascunho`, filtro);
    return data;
  }

  static async GetDesativarRascunho(id) {
    const { data } = await api.get(`${PATH}/desativar-rascunho`, { params: { id: id } });
    return data;
  }

  static async GetRascunho(id) {
    const { data } = await api.get(`${PATH}/get-rascunho`, { params: { id: id } });
    return data;
  }
}

export default Rascunho;
