import React from 'react';

import './style.scss';

const AppTitle = ({ children }) => {
  return (
    <div className="appTitle pt-4 pb-4">
      <h1 className="texto">{children}</h1>
      <div className="divider" />
    </div>
  );
};

export default AppTitle;
