import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

// import InputFile from 'components/InputFile';
import Button from 'components/Button';

import SemFoto from 'assets/imgs/SEMFOTO.png';
import QualidadeImagem from '../../assets/imgs/dicasQualidade.jpg';

import './styles.scss';
import InputUploadS3 from 'components/InputUploadS3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TextArea from 'components/TextArea';

const UploadImageEvidenciaExtra = ({
  arquivos,
  onChangeFile,
  removeFile,
  evidenciaId,
  addFileFunc,
  changeDescription
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    window.showLoader();
    setShow(true);
    window.hideLoader();
  };

  return (
    <>
      <div className="row">
        <h5 className={`my-3`}>
          <>
            <b>ESCOLHA OS ARQUIVOS</b>{' '}
            <span>
              (Atenção a qualidade de imagens! Consulte o guia{' '}
              <span className="link-primary" onClick={() => handleShow()}>
                AQUI
              </span>
              )
            </span>
          </>
        </h5>
        <div className="col-12">
          {arquivos.map((item, index) => (
            <div
              className="row card-imagem-evidencia-extra"
              key={item.id}
              style={{ border: '1px black solid' }}>
              <div className="col-4">
                <div
                  className="image p-3 d-flex justify-content-center align-items-center"
                  style={{ height: '270px' }}>
                  {item.file?.name ? (
                    <img
                      src={URL.createObjectURL(item.file)}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  ) : item.urlArquivo?.length > 0 ? (
                    <img
                      src={item.urlArquivo}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  ) : item.urlS3?.length > 0 ? (
                    // Imagem de url S3 existente -----------------------------------------------------------------------------
                    <img
                      src={item.urlS3}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  ) : (
                    <img
                      src={SemFoto}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  )}
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <TextArea
                    placeholder="Escreva uma legenda para a foto de até 200 caracteres"
                    label="Legenda da Foto"
                    rows="5"
                    col="10"
                    max={200}
                    campoObrigatorio
                    value={item?.legenda}
                    onChange={(e) => {
                      if (e.target.value === undefined || e.target.value.length <= 200) {
                        changeDescription(item.id, e.target.value);
                      } else {
                        changeDescription(item.id, e.target.value.substring(0, 200));
                      }
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="d-flex justify-content-center flex-column p-2">
                      <InputUploadS3
                        evidenciaId={evidenciaId}
                        fontSizeLabel={12}
                        setItemUrl={(url) => {
                          onChangeFile(url, item.id || index);
                        }}>
                        Faça upload do arquivo
                      </InputUploadS3>
                    </div>
                  </div>
                  <div className="col-4 d-flex align-items-center">
                    <div
                      className="d-flex justify-content-center pb-2"
                      style={{ paddingTop: '20px !important' }}>
                      <Button
                        color="link"
                        className="text-decoration-none"
                        onClick={() => removeFile(item.id)}>
                        <span className="text-danger text-decoration-none">Remover imagem</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div
            className="col-12 d-flex flex-row-reverse button-add-evidencia-extra"
            key={'button-add-more'}>
            <button
              className="btn btn-primary px-4"
              onClick={addFileFunc}
              style={{
                borderRadius: '10px'
              }}>
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  fontSize: '10px'
                }}
              />{' '}
              Adicionar Foto
            </button>
          </div>
        </div>
      </div>
      {/* Modal  */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>Instruções para garantir a qualidade das fotos de evidências:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            <img src={QualidadeImagem} className="img-fluid" alt="QualidadeImagem" />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="ms-2 p-2 fw-semibold" width="320px" onClick={() => handleClose()}>
            FECHAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadImageEvidenciaExtra;
