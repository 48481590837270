import React from 'react';

import LogoImg from 'assets/imgs/logo.png';
import './style.scss';

const Logo = () => {
  return (
    <div className="logo p-1">
      <img src={LogoImg} alt="img logo" />
    </div>
  );
};

export default Logo;
