import React from 'react';

import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import { Table } from 'react-bootstrap';
import { numberToStringCurrency } from 'helpers/money';
import DisplayImageType from 'components/DisplayImageType';

const Resume = ({ backTab, saveForm, formData, tipoArquivoList }) => {
  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Rede</b>
            </td>
            <td className="col-8">{formData.rede?.nome}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Observações</b>
            </td>
            <td className="col-8">{formData.resultados}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Investimento</b>
            </td>
            <td className="col-8">
              {formData.investimento === -1
                ? 'Ativação contemplada no JBP'
                : numberToStringCurrency(formData.investimento)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Qtd. Total de Lojas</b>
            </td>
            <td className="col-8">{formData.qtdLojasTotal}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Qtd. Lojas Ativadas</b>
            </td>
            <td className="col-8">{formData.qtdLojasAtivadas}</td>
          </tr>
        </tbody>
      </Table>

      <div className="row">
        <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
        <DisplayImageType arquivos={formData.arquivos} tipoArquivoList={tipoArquivoList} />
      </div>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
