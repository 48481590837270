import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Login from 'services/Login';

const LoginPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    (async () => {
      window.showLoader();
      if (token) {
        localStorage.setItem('ativacoescanalas_tokenOnOff', token);
        const data = await Login.LoginWithToken(token);
        if (data.value.authenticated) {
          navigate('/');
        }
      }
      window.hideLoader();
    })();
  }, []);

  return (
    <>
      <div className="vh-100"></div>
    </>
  );
};

export default LoginPage;
