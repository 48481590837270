import React, { useEffect, useState } from 'react';

import FormBox from 'components/FormBox';
import PageTitle from 'components/PageTitle';

import { useNavigate, useParams } from 'react-router-dom';
import EvidenciaVisibilidadeMidiaIndoor from 'services/EvidenciaVisibilidadeMidiaIndoor';
import Resume from './resume';
import Form from './form';
import Evidencia from 'services/Evidencia';
import jsonToFormData from 'helpers/jsonBuildFormData';
import Rascunho from 'services/Rascunho';

function Visibilidade() {
  const navigate = useNavigate();
  const { id, rascunho } = useParams();

  const [activeTab, setActiveTab] = useState({
    pagina: 'form',
    subTitle: 'Preencha os dados abaixo e inclua as mídias:'
  });
  const [formData, setFormData] = useState({
    evidenciaId: null,
    redeId: null,
    rede: '',
    qtdLojasTotal: null,
    qtdLojasAtivadas: null,
    resultados: '',
    investimento: '',
    mpdvs: [],
    investimentoJBPRadio: false,
    arquivos: [{ id: 0, type: 0 }]
  });
  const [errors, setErrors] = useState([]);
  const [mpdvList, setMpdvList] = useState([]);

  useEffect(() => {
    (async () => {
      window.showLoader();

      let { value: tipoMaterialValue } = await Evidencia.GetListMaterial();
      tipoMaterialValue = tipoMaterialValue.map((item) => {
        return {
          label: item.nome,
          value: item.materialComunicacaoId,
          urlImagem: item.urlImagem,
          isLink: item.isLink
        };
      });
      setMpdvList(tipoMaterialValue);

      if (!formData.evidenciaId) {
        //edit
        if (id > 0) {
          if (rascunho === 'rascunho') {
            let { value } = await Rascunho.GetRascunho(id);
            value = JSON.parse(value.rascunho);
            if (value.arquivos && value.arquivos.length > formData.arquivos.length) {
              while (formData.arquivos.length < value.arquivos.length) {
                const index = formData.arquivos.length;
                formData.arquivos.push({ id: index, type: 0 });
              }
            }
            setFormData({
              ...formData,
              ...value,
              updateFileIdCounter: true,
              investimentoJBPRadio: Boolean(value.investimento === -1),
              arquivos: formData.arquivos.map((arquivo, index) => ({
                ...arquivo,
                ...value.arquivos[index]
              }))
            });
          } else {
            const { value } = await EvidenciaVisibilidadeMidiaIndoor.edit(id);
            if (value.arquivos && value.arquivos.length > formData.arquivos.length) {
              while (formData.arquivos.length < value.arquivos.length) {
                const index = formData.arquivos.length;
                formData.arquivos.push({ id: index, type: 0 });
              }
            }
            setFormData({
              ...formData,
              ...value,
              updateFileIdCounter: true,
              investimentoJBPRadio: Boolean(value.investimento === -1),
              arquivos: formData.arquivos.map((arquivo, index) => ({
                ...arquivo,
                ...value.arquivos[index]
              }))
            });
          }
        }
        //create
        else {
          const { value } = await EvidenciaVisibilidadeMidiaIndoor.create();
          setFormData({ ...formData, evidenciaId: value.evidenciaId });
        }
      }
      window.hideLoader();
    })();
  }, []);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const saveForm = async () => {
    window.showLoader();

    const formRequest = jsonToFormData(formData);
    const data = await EvidenciaVisibilidadeMidiaIndoor.save(formRequest);
    if (data.value) {
      window.hideLoader();
      navigate('/sucesso');
    } else {
      window.scrollTo(0, 0);
      setErrors(data.errors);
      window.hideLoader();
    }
  };

  const renderTab = () => {
    let contentNow = <h1>Página não encontrada</h1>;
    if (activeTab.pagina === 'form') {
      contentNow = (
        <Form
          formData={formData}
          setFormData={setFormData}
          nextTab={() => changeTab({ pagina: 'resume', subTitle: 'Confira todos os dados abaixo' })}
          mpdvList={mpdvList}
        />
      );
    }
    if (activeTab.pagina === 'resume') {
      contentNow = (
        <Resume
          backTab={() =>
            changeTab({ pagina: 'form', subTitle: 'Preencha os dados abaixo e inclua as mídias:' })
          }
          saveForm={saveForm}
          formData={formData}
          tipoArquivoList={mpdvList}
        />
      );
    }
    return contentNow;
  };

  return (
    <div className="visibilidade">
      <PageTitle subTitle="Preencha os dados abaixo e inclua as mídias:">
        ATIVAÇÃO - INDOOR / VISIBILIDADE
      </PageTitle>
      <FormBox>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            Erro ao realizar o envio, por favor confira os dados e tente novamente.
          </div>
        )}
        <div className="row">{renderTab()}</div>
      </FormBox>
    </div>
  );
}

export default Visibilidade;
