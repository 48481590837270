import React from 'react';
import { Table } from 'react-bootstrap';
import { dateFormat } from 'helpers/Date';
import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import { numberToStringCurrency } from 'helpers/money';
import DisplayImage from 'components/DisplayImage';

const Resume = ({ backTab, saveForm, formData }) => {
  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Tipo de Ativação</b>
            </td>
            <td className="col-8">Encarte {formData.tipoEncarte}</td>
          </tr>
          {formData.solicitacao.solicitacaoId && (
            <>
              <tr>
                <td className="col-4">
                  <b>Número da Solicitação</b>
                </td>
                <td className="col-8">{formData.solicitacao?.solicitacaoId}</td>
              </tr>
              <tr>
                <td className="col-4">
                  <b>Loja</b>
                </td>
                <td className="col-8">{formData.solicitacao?.razaoSocial}</td>
              </tr>
              <tr>
                <td className="col-4">
                  <b>Vigência da Solicitação</b>
                </td>
                <td className="col-8">
                  {dateFormat(formData.solicitacao?.inicio)} -{' '}
                  {dateFormat(formData.solicitacao?.fim)}{' '}
                </td>
              </tr>
              <tr>
                <td className="col-4">
                  <b>Custo Multimaxi</b>
                </td>
                <td className="col-8">
                  {numberToStringCurrency(formData.solicitacao?.custoTotal)}
                </td>
              </tr>
            </>
          )}
          {formData.redeId && (
            <tr>
              <td className="col-4">
                <b>Rede</b>
              </td>
              <td className="col-8">{formData.rede?.nome}</td>
            </tr>
          )}
          <tr>
            <td className="col-4">
              <b>Investimento da Ação</b>
            </td>
            <td className="col-8">
              {formData.investimento === -1
                ? 'Ativação contemplada no JBP'
                : numberToStringCurrency(formData.investimento)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Vigência do Impresso</b>
            </td>
            <td className="col-8">
              {dateFormat(formData.inicio)} - {dateFormat(formData.fim)}{' '}
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="row">
        <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
        <DisplayImage arquivos={formData.arquivos} />
      </div>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
