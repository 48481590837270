import React from 'react';

const DisplayImageExtra = ({ arquivos }) => {
  return (
    <>
      {arquivos
        .filter((item) => item.urlArquivo?.length > 0 || item.urlS3?.length > 0)
        .map((item, index) => {
          const url = item.urlS3 || item.urlArquivo;
          return (
            <div
              className="row col-12 p-3 m-2"
              style={{
                width: '100%',
                backgroundColor: '#f3f3f3',
                borderRadius: '4px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
              }}
              key={item.id}>
              <div className="col-md-4 col-sm-12">
                <img
                  key={index}
                  src={url}
                  style={{ maxHeight: '240px' }}
                  className="img-fluid"
                  alt="imagem"
                />
              </div>
              <div className="col-md-8 col-sm-12">
                <span>{item.legenda}</span>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default DisplayImageExtra;
