import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const Sucess = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <FontAwesomeIcon
        className="p-4"
        icon={faCircleCheck}
        style={{ color: '#28B83B', fontSize: '200px' }}
      />
      <h2>Evidências Enviadas com Sucesso!</h2>
      <h5>
        <Link className="text-reset text-decoration-none" to="/">
          Clique aqui para voltar para a página Home
        </Link>
      </h5>
    </div>
  );
};

export default Sucess;
