import React, { useEffect, useRef, useState } from 'react';

import SearchRede from 'components/SearchRede';
import TextArea from 'components/TextArea';
import InputCurrency from 'components/InputCurrency';
import UploadImage from 'components/UploadImage';
import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import SelectMult from 'components/SelectMult';

import RadioBox from 'components/RadioBox';
import Input from 'components/Input';
import TimerRascunho from 'components/TimerRascunho';
import { flushSync } from 'react-dom';
import MyDatePicker from 'components/DatePicker';

const Form = ({
  nextTab,
  formData,
  setFormData,
  marcasList,
  validacaoCampos,
  setValidacaoCampos
}) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();
  const fileIdCounter = useRef(formData.arquivos.length || 0);

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }

    if (formData?.updateFileIdCounter) {
      fileIdCounter.current = formData.arquivos.length;
      setFormData({ ...formData, updateFileIdCounter: false });
    }
  }, [formData]);

  const optionValidacaoCampos = [
    { label: 'SIM', value: true },
    { label: 'NÃO', value: false }
  ];

  const validacaoFormulario = async () => {
    let todosErros = [];

    // validação de campos obrigatorios
    validacaoCampos.rede && !formData.redeId && todosErros.push('Rede');
    validacaoCampos.investimento &&
      !formData.investimento &&
      todosErros.push('Investimento na ação');
    validacaoCampos.marcasPositivadas &&
      !formData.marcasPositivadas &&
      todosErros.push('Marcas Positivadas');
    !formData.inicio && todosErros.push('Inicio do Período da Ação');
    !formData.fim && todosErros.push('Fim do Período da Ação');

    !formData.resultados && todosErros.push('Contexto do Evento');

    const arquivosWithFile = formData.arquivos.filter((item) => item.file || item.urlArquivo);
    !arquivosWithFile.length && todosErros.push('Arquivos');

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeFile = (file, id) => {
    window.showLoader();
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return { ...item, urlArquivo: file };
      } else {
        return item;
      }
    });

    window.hideLoader();

    setFormData({ ...formData, arquivos: arquivos });
  };

  const addFileFunc = () => {
    window.showLoader();
    const arquivos = [...formData.arquivos, { id: fileIdCounter.current++ }];
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const removeFileFunc = (id) => {
    window.showLoader();
    const arquivos = formData.arquivos.filter((item) => item.id !== id);
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  return (
    <>
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo <b>{value}</b> é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>
      <div className="col-12">
        <RadioBox
          label="Teve rede vinculada ao evento?"
          name="vinculoEvento"
          selected={validacaoCampos.rede}
          options={optionValidacaoCampos}
          onChange={(e) => {
            e === false
              ? setFormData({ ...formData, redeId: null, rede: '' })
              : setFormData({ ...formData, redeParceiro: '' });
            setValidacaoCampos({ ...validacaoCampos, rede: e });
          }}
        />
      </div>
      {validacaoCampos.rede && (
        <SearchRede campoObrigatorio changeRede={setFormData} formData={formData} />
      )}

      {!validacaoCampos.rede && (
        <div className="col-12">
          {/* Nome da rede parceira ------------------------------------------------------------------------------------------------------ */}
          <Input
            name="redeParceiro"
            type="text"
            label="Nome do parceiro (se houver)"
            value={formData.redeParceiro}
            onChange={(e) => {
              setFormData({ ...formData, redeParceiro: e.target.value });
            }}
          />
        </div>
      )}
      <div className="row">
        <div className="col-4">
          <RadioBox
            label="Teve investimento?"
            name="investimentoRadio"
            selected={validacaoCampos.investimento}
            options={optionValidacaoCampos}
            onChange={(e) => {
              e === false && setFormData({ ...formData, investimento: '' });
              setValidacaoCampos({ ...validacaoCampos, investimento: e });
            }}
          />
        </div>
        {validacaoCampos.investimento && (
          <div className="col-8">
            <InputCurrency
              value={formData.investimento}
              campoObrigatorio
              disabled={!validacaoCampos.investimento}
              onChange={(value) => setFormData({ ...formData, investimento: value })}
              label="Investimento"
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-4">
          <RadioBox
            label="Teve marcas positivadas?"
            name="marcasPositivadas"
            selected={validacaoCampos.marcasPositivadas}
            options={optionValidacaoCampos}
            onChange={(value) => {
              value === false && setFormData({ ...formData, marcasPositivadas: [] });
              setValidacaoCampos({ ...validacaoCampos, marcasPositivadas: value });
            }}
          />
        </div>
        {validacaoCampos.marcasPositivadas && (
          <div className="col-8">
            <SelectMult
              name="categoriasPositivadas"
              label="Qual marca foi positivada?"
              options={marcasList}
              campoObrigatorio
              disabled={!validacaoCampos.marcasPositivadas}
              placeholder="Seleção de até 5"
              value={formData.marcasPositivadas}
              maxItem={5}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  marcasPositivadas: value.length <= 5 ? value : formData.marcasPositivadas
                })
              }
            />
          </div>
        )}
      </div>
      <h5>Período do Evento</h5>
      <div className="col-6">
        <MyDatePicker
          placeholderText="Data Inicial"
          selected={formData.inicio}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, inicio: value })}
        />
      </div>
      <div className="col-6">
        <MyDatePicker
          placeholderText="Data Final"
          selected={formData.fim}
          minDate={formData.inicio}
          disabled={!formData.inicio}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, fim: value })}
        />
      </div>
      <div className="col-12">
        <TextArea
          placeholder="Descreva quaisquer observações, aprendizados e pontos de melhoria percebidos. Máximo 200 caracteres."
          label="Contexto do Evento"
          row="1"
          col="200"
          max={200}
          campoObrigatorio
          value={formData.resultados}
          onChange={(e) => {
            if (e.target.value === undefined || e.target.value.length <= 200) {
              setFormData({
                ...formData,
                resultados: e.target.value
              });
            } else {
              setFormData({
                ...formData,
                resultados: e.target.value.substring(0, 200)
              });
            }
          }}
        />
      </div>
      {formData.arquivos && (
        <UploadImage
          arquivos={formData.arquivos}
          addFileFunc={addFileFunc}
          onChangeFile={onChangeFile}
          removeFile={removeFileFunc}
          evidenciaId={formData.evidenciaId}
        />
      )}
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        {/* <SaveButton onClick={() => nextTab()} /> */}
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              flushSync(() => {
                setChangeCount(0);
              });
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
        <TimerRascunho
          changeCount={changeCount}
          setChangeCount={setChangeCount}
          formData={formData}
        />
      </div>
    </>
  );
};

export default Form;
