import React, { useState, useEffect, useRef } from 'react';

import SearchRede from 'components/SearchRede';
import Input from 'components/Input';

import TextArea from 'components/TextArea';

import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';

import TimerRascunho from 'components/TimerRascunho';
import { flushSync } from 'react-dom';
import InvestimentoJBP from 'components/InvestimentoJBP';
import UploadMedia from 'components/UploadMedia';

const Form = ({ formData, setFormData, nextTab, mpdvList }) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();
  const fileIdCounter = useRef(formData.arquivos.length || 0);

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }

    if (formData?.updateFileIdCounter) {
      fileIdCounter.current = formData.arquivos.length;
      setFormData({ ...formData, updateFileIdCounter: false });
    }
  }, [formData]);

  const validacaoFormulario = async () => {
    let todosErros = [];
    // validação de campos obrigatorios
    !formData.redeId && todosErros.push('Rede');
    !formData.qtdLojasTotal && todosErros.push('Qtd. Total de Lojas');
    !formData.qtdLojasAtivadas && todosErros.push('Qtd. Lojas Ativas');
    !formData.investimento && todosErros.push('Investimento');
    !formData.resultados && todosErros.push('Observações');

    const listaZerada = !formData.arquivos || formData.arquivos.length == 0;
    listaZerada && todosErros.push('Arquivos Vazios');

    const arquivosEmpty = formData.arquivos.filter(
      (item) => item.type == 0 && !(item.file || item.urlArquivo)
    );
    arquivosEmpty.length && todosErros.push('Arquivos');

    const arquivosWithFile = formData.arquivos.filter(
      (item) => item.type > 0 && !(item.file || item.urlArquivo)
    );
    arquivosWithFile.length && todosErros.push('Carregar Imagem Arquivos');

    const arquivosWithoutType = formData.arquivos.filter(
      (item) => item.type == 0 && (item.file || item.urlArquivo)
    );
    arquivosWithoutType.length && todosErros.push('Arquivos Sem Tipo');

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeFile = (file, id) => {
    window.showLoader();
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return { ...item, urlArquivo: file };
      } else {
        return item;
      }
    });

    window.hideLoader();

    setFormData({ ...formData, arquivos: arquivos });
  };

  const onChangeType = (typeSelected, id) => {
    window.showLoader();

    var arquivo = formData.arquivos.find((item) => item.id === id);
    var newType = mpdvList.find((item) => item.value === typeSelected);

    if (arquivo?.type === 0 && newType.isLink) {
      arquivo.urlArquivo = '';
    }

    if (arquivo?.type !== 0 && arquivo?.type !== typeSelected) {
      var oldType = mpdvList.find((item) => item.value === arquivo.type);
      if (oldType.isLink !== newType.isLink) {
        arquivo.urlArquivo = '';
      }
    }

    arquivo.type = typeSelected;
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return arquivo;
      } else {
        return item;
      }
    });

    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const changeLinkItemFunc = (id, urlNova) => {
    const newArquivos = formData.arquivos.map((item) =>
      item.id === id ? { ...item, urlArquivo: urlNova } : item
    );
    setFormData({ ...formData, arquivos: newArquivos });
  };

  const addFileFunc = () => {
    window.showLoader();
    const arquivos = [...formData.arquivos, { id: fileIdCounter.current++, type: 0 }];
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const removeFileFunc = (id) => {
    window.showLoader();
    const arquivos = formData.arquivos.filter((item) => item.id !== id);
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  return (
    <>
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo <b>{value}</b> é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>
      <SearchRede changeRede={setFormData} campoObrigatorio formData={formData} />

      <div className="col-6">
        <Input
          name="totalLojas"
          type="number"
          placeholder="00"
          label="Qtd. Total de Lojas"
          min={1}
          campoObrigatorio
          value={formData.qtdLojasTotal || ''}
          onChange={(e) => setFormData({ ...formData, qtdLojasTotal: e.target.value })}
        />
      </div>
      <div className="col-6">
        <Input
          name="lojasAtivas"
          type="number"
          placeholder="00"
          label="Qtd. Lojas Ativadas"
          min={1}
          campoObrigatorio
          value={formData.qtdLojasAtivadas || ''}
          onChange={(e) => setFormData({ ...formData, qtdLojasAtivadas: e.target.value })}
        />
      </div>

      <InvestimentoJBP formData={formData} setFormData={setFormData} />

      <div className="col-12">
        <TextArea
          placeholder="Descreva quaisquer observações sobre a ação em no máximo 200 caracteres."
          label="Observações"
          row="1"
          col="200"
          max={200}
          campoObrigatorio
          value={formData.resultados}
          onChange={(e) => {
            if (e.target.value === undefined || e.target.value.length <= 200) {
              setFormData({
                ...formData,
                resultados: e.target.value
              });
            } else {
              setFormData({
                ...formData,
                resultados: e.target.value.substring(0, 200)
              });
            }
          }}
        />
      </div>

      {formData.arquivos && (
        <UploadMedia
          arquivos={formData.arquivos}
          addFileFunc={addFileFunc}
          removeFile={removeFileFunc}
          onChangeFile={onChangeFile}
          onChangeType={onChangeType}
          evidenciaId={formData.evidenciaId}
          listTypes={mpdvList}
          changeLinkItemFunc={changeLinkItemFunc}
        />
      )}
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              flushSync(() => {
                setChangeCount(0);
              });
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
        <TimerRascunho
          changeCount={changeCount}
          setChangeCount={setChangeCount}
          formData={formData}
        />
      </div>
    </>
  );
};

export default Form;
