import React from 'react';
import HomeButton from 'components/HomeButton';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="contentHome">
      <div className="d-flex justify-content-center flex-wrap align-items-center pt-2 pb-5">
        <Link to="/meus-rascunhos">
          <Button className="m-2 px-5 py-2">MEUS RASCUNHOS</Button>
        </Link>
        <Link to="/baixar-relatorios">
          <Button color="danger" className="m-2 px-5 py-2">
            BAIXAR RELATÓRIOS
          </Button>
        </Link>
        <Link to="/historico-evidencias">
          <Button className="m-2 px-5 py-2">HISTÓRICO DE EVIDÊNCIAS</Button>
        </Link>
        {/* <Link to="/dashboard-resumo">
          <Button className="m-2 px-5 py-2">DASHBOARD RESUMO</Button>
        </Link> */}
      </div>
      <HomeButton />
    </div>
  );
};

export default Home;
