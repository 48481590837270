/* eslint-disable no-undef */
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
});

const apiFormData = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*'
  }
});

api.interceptors.request.use(function (config) {
  const bearerToken = localStorage.getItem('ativacoescanalas')
    ? `Bearer ${localStorage.getItem('ativacoescanalas_token')}`
    : '';

  config.headers.Authorization = bearerToken;

  return config;
});

apiFormData.interceptors.request.use(function (config) {
  const bearerToken = localStorage.getItem('ativacoescanalas')
    ? `Bearer ${localStorage.getItem('ativacoescanalas_token')}`
    : '';

  config.headers.Authorization = bearerToken;

  return config;
});

api.interceptors.response.use(
  function (response) {
    if (response.data?.code === 500) {
      // excessão tratada no servidor
      window.location = '/erro';
      return undefined;
    }

    return response;
  },
  function (error) {
    if (401 === error.response?.status || 400 === error.response?.status) {
      localStorage.removeItem('ativacoescanalas_tokenOnOff');
      localStorage.removeItem('ativacoescanalas_token');
      localStorage.removeItem('ativacoescanalas');

      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // development build code
        window.location = '/erro';
      } else {
        // production build code
        window.location = process.REACT_APP_URL_ACESSO;
      }
    } else if (500 === error.response?.status) {
      // mostrar tela de um erro inesperado na tela
      window.location = '/erro';
    } else if (403 === error.response?.status) {
      // mostrar uma tela de voce nao tem permissao pra finalizar essa opecação
      // window.location = '/acesso-restrito';
      window.location = '/erro';
    } else if ('ERR_NETWORK' === error.code) {
      window.showToast();
      window.hideLoader();
    } else {
      return Promise.reject(error);
    }
  }
);

apiFormData.interceptors.response.use(
  function (response) {
    if (response.data?.code === 500) {
      // excessão tratada no servidor
      window.location = '/erro';
      return undefined;
    }

    return response;
  },
  function (error) {
    if (401 === error.response?.status || 400 === error.response?.status) {
      localStorage.removeItem('ativacoescanalas_tokenOnOff');
      localStorage.removeItem('ativacoescanalas_token');
      localStorage.removeItem('ativacoescanalas');

      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // development build code
        window.location = '/erro';
      } else {
        // production build code
        window.location = process.REACT_APP_URL_ACESSO;
      }
    } else if (500 === error.response?.status) {
      // mostrar tela de um erro inesperado na tela
      window.location = '/erro';
    } else if (403 === error.response?.status) {
      // mostrar uma tela de voce nao tem permissao pra finalizar essa opecação
      // window.location = '/acesso-restrito';
      window.location = '/erro';
    } else {
      return Promise.reject(error);
    }
  }
);

export { api as default, apiFormData };
