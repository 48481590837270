import api, { apiFormData } from 'api';

const PATH = '/EvidenciaEventos';
class EvidenciaEventos {
  static async get(id) {
    const { data } = await api.get(`${PATH}/get`, { params: { id } });
    return data;
  }
  static async create() {
    const { data } = await api.get(`${PATH}/create`);
    return data;
  }
  static async edit(id) {
    const { data } = await api.get(`${PATH}/edit`, { params: { id } });
    return data;
  }
  static async save(dataBody) {
    const { data } = await apiFormData.post(`${PATH}/save`, dataBody);
    return data;
  }
}

export default EvidenciaEventos;
