import React from 'react';
import { Outlet } from 'react-router-dom';

import Protected from 'routes/Protected';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import AppTitle from 'components/AppTitle';

import './style.scss';

const LayoutLogin = () => {
  const data = JSON.parse(localStorage.getItem('ativacoescanalas'));
  return (
    <Protected>
      <div className="layoutLogin">
        <Navbar nome={data?.value?.nome ? data.value.nome : ''} />
        <div className="container">
          <AppTitle>RELATÓRIO DE EVIDÊNCIAS ON / OFF</AppTitle>
          <Outlet />
        </div>
      </div>
      <Footer />
    </Protected>
  );
};

export default LayoutLogin;
