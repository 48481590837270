import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import React from 'react';
import { Table } from 'react-bootstrap';

import DisplayImage from 'components/DisplayImage';
import { dateFormat } from 'helpers/Date';
import { numberToStringCurrency } from 'helpers/money';

const Resume = ({ backTab, saveForm, formData }) => {
  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Tipo de Ativação</b>
            </td>
            <td className="col-8">LMPM</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Rede</b>
            </td>
            <td className="col-8">{formData.rede?.nome}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Mecânica da Ação</b>
            </td>
            <td className="col-8">{formData.mecanicaAcao}</td>
          </tr>
          {formData.tipoAcao == 'Cross-Category' && (
            <tr>
              <td className="col-4">
                <b>Marca Parceira</b>
              </td>
              <td className="col-8">{formData.marcaParceira}</td>
            </tr>
          )}
          <tr>
            <td className="col-4">
              <b>Qtd Loja Ativadas</b>
            </td>
            <td className="col-8">{formData.qtdLojasAtivadas}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Qtd Loja Total</b>
            </td>
            <td className="col-8">{formData.qtdLojasTotal}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Investimento da Ação</b>
            </td>
            <td className="col-8">{numberToStringCurrency(formData.investimento)}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Período da Ação</b>
            </td>
            <td className="col-8">
              {dateFormat(formData.inicio)} - {dateFormat(formData.fim)}{' '}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Aprendizados e Ptos. Melhoria</b>
            </td>
            <td className="col-8">{formData.resultados}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Sell Out Inicial</b>
            </td>
            <td className="col-8">{numberToStringCurrency(formData.sellOutInicial)}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Sell Out Final</b>
            </td>
            <td className="col-8">{numberToStringCurrency(formData.sellOutFinal)}</td>
          </tr>
        </tbody>
      </Table>

      <div className="row">
        <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
        <DisplayImage arquivos={formData.arquivos} />
      </div>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
