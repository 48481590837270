import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function Protected({ children }) {
  // let auth = useAuth();
  let location = useLocation();

  let auth = true;
  if (!auth) {
    return (
      <Navigate to={`/login?urlReturn=${location.pathname}`} state={{ from: location }} replace />
    );
  }

  return children;
}

export default Protected;
