import Button from 'components/Button';
import React from 'react';
import { useNavigate } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

function BackButton({ backFunction }) {
  const navigate = useNavigate();
  return (
    <Button
      className="text-uppercase font-weight-bold btn-lg"
      onClick={backFunction ? backFunction : () => navigate(-1)}>
      <FontAwesomeIcon icon={faAngleLeft} className="pe-2" />
      <span style={{ fontSize: '18px', fontWeight: '500' }}>Voltar</span>
    </Button>
  );
}

export default BackButton;
