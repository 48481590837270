import React from 'react';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

function SaveButton({ onClick }) {
  return (
    <Button className="text-uppercase font-weight-bold btn-lg" onClick={onClick}>
      <FontAwesomeIcon icon={faFloppyDisk} className="pe-2" />
      <span style={{ fontSize: '18px', fontWeight: '500' }}>SALVAR</span>
    </Button>
  );
}

export default SaveButton;
