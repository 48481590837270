import React, { useEffect, useRef, useState } from 'react';

import SearchSolicitacao from 'components/SearchSolicitacao';
import BackButton from 'components/BackButton';
import MyDatePicker from 'components/DatePicker';
import InputCurrency from 'components/InputCurrency';
import SaveButton from 'components/SaveButton';
import RadioBox from 'components/RadioBox';
import SearchRede from 'components/SearchRede';
import UploadImage from 'components/UploadImage';
import TimerRascunho from 'components/TimerRascunho';
import { flushSync } from 'react-dom';
import InvestimentoJBP from 'components/InvestimentoJBP';

const Form = ({ formData, setFormData, nextTab, resetFormDataValue }) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();
  const fileIdCounter = useRef(10);

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }

    if (formData?.updateFileIdCounter) {
      fileIdCounter.current = formData.arquivos.length;
      setFormData({ ...formData, updateFileIdCounter: false });
    }
  }, [formData]);

  const validacaoFormulario = async () => {
    let todosErros = [];
    // validação de campos obrigatorios

    if (formData.tipoEncarte === 'exclusivo') {
      !formData.solicitacao?.solicitacaoId > 0 && todosErros.push('Nro da Solicitação');
    }
    if (formData.tipoEncarte === 'rede') {
      !formData.redeId && todosErros.push('Rede');
    }

    !formData.inicio && todosErros.push('Inicio da Vigência do Impresso');
    !formData.fim && todosErros.push('Fim  da Vigência do Impresso');
    !formData.investimento && todosErros.push('Investimento na ação');

    const arquivosWithFile = formData.arquivos.filter(
      (item) => item.file || item.urlS3 || item.urlArquivo
    );
    !arquivosWithFile.length && todosErros.push('Arquivos');

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const addFileFunc = () => {
    window.showLoader();
    const arquivos = [...formData.arquivos, { id: fileIdCounter.current++ }];
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const removeFileFunc = (id) => {
    window.showLoader();
    const arquivos = formData.arquivos.filter((item) => item.id !== id);
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const onChangeFile = (file, id) => {
    window.showLoader();
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return { ...item, urlArquivo: file, urlS3: '' };
      } else {
        return item;
      }
    });

    window.hideLoader();

    setFormData({ ...formData, arquivos: arquivos });
  };

  return (
    <div className="row">
      {/* Erros de validacao ------------------------------------------------------------------------------------------------ */}
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo <b>{value}</b> é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>

      <div className="col-12">
        <RadioBox
          label="Qual o tipo de encarte utilizado?"
          options={[
            { label: 'ENCARTE EXCLUSIVO', value: 'exclusivo' },
            { label: 'ENCARTE REDE', value: 'rede' }
          ]}
          selected={formData.tipoEncarte}
          onChange={(e) => {
            setFormData(resetFormDataValue(e, formData));
          }}
        />
      </div>
      {formData.tipoEncarte === 'exclusivo' && (
        <>
          {/* Busca/Selecao de Solicitacoes ------------------------------------------------------------------------------------- */}
          <div className="col-12">
            <SearchSolicitacao changeSolicitacao={setFormData} formData={formData} />
          </div>

          {/* Vigencia da Solicitacao ------------------------------------------------------------------------------------------- */}
          <h5>Data de vigência inicial da solicitação</h5>
          <div className="col-6">
            <MyDatePicker
              placeholderText="Data Inicial"
              selected={formData.solicitacao?.inicio || ''}
              disabled={true}
              campoObrigatorio
            />
          </div>
          <div className="col-6">
            <MyDatePicker
              placeholderText="Data Final"
              selected={formData.solicitacao?.fim || ''}
              disabled={true}
              campoObrigatorio
            />
          </div>
          {/* Vigencia do Impresso ---------------------------------------------------------------------------------------------- */}
          <h5>Vigência do Impresso</h5>
          <div className="col-6">
            <MyDatePicker
              placeholderText="Data Inicial"
              selected={formData.inicio}
              campoObrigatorio
              onChange={(value) => setFormData({ ...formData, inicio: value })}
            />
          </div>
          <div className="col-6">
            <MyDatePicker
              placeholderText="Data Final"
              selected={formData.fim}
              minDate={formData.inicio}
              disabled={!formData.inicio}
              campoObrigatorio
              onChange={(value) => setFormData({ ...formData, fim: value })}
            />
          </div>

          {/* Custo Multimaxi --------------------------------------------------------------------------------------------------- */}
          <div className="col-12">
            <InputCurrency
              label="Custo Multimaxi"
              value={formData?.solicitacao?.custoTotal}
              disabled={true}
              campoObrigatorio
            />
          </div>

          <InvestimentoJBP
            formData={formData}
            setFormData={setFormData}
            label="Custo Veiculação do Encarte (Pago ao Cliente)"
          />
        </>
      )}
      {formData.tipoEncarte === 'rede' && (
        <>
          {/* Busca Rede -------------------------------------------------------------------------------------------------------- */}
          <SearchRede
            changeRede={setFormData}
            formData={formData}
            value={formData.redeId}
            campoObrigatorio
          />
          {/* Vigencia do Impresso ---------------------------------------------------------------------------------------------- */}
          <h5>Vigência do Impresso</h5>
          <div className="col-6">
            <MyDatePicker
              placeholderText="Data Inicial"
              selected={formData.inicio}
              campoObrigatorio
              onChange={(value) => setFormData({ ...formData, inicio: value })}
            />
          </div>
          <div className="col-6">
            <MyDatePicker
              placeholderText="Data Final"
              selected={formData.fim}
              minDate={formData.inicio}
              disabled={!formData.inicio}
              campoObrigatorio
              onChange={(value) => setFormData({ ...formData, fim: value })}
            />
          </div>
          {/* Investimento do Cliente ------------------------------------------------------------------------------------------- */}
          <InvestimentoJBP
            formData={formData}
            setFormData={setFormData}
            label="Custo Veiculação do Encarte (Pago ao Cliente)"
          />
        </>
      )}

      {/* Imagens ----------------------------------------------------------------------------------------------------------- */}
      {formData.arquivos && (
        <UploadImage
          arquivos={formData.arquivos}
          addFileFunc={addFileFunc}
          onChangeFile={onChangeFile}
          removeFile={removeFileFunc}
          evidenciaId={formData.evidenciaId}
        />
      )}
      {/* Botoes Voltar/Salvar ---------------------------------------------------------------------------------------------- */}
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              flushSync(() => {
                setChangeCount(0);
              });
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
      </div>
      <TimerRascunho
        changeCount={changeCount}
        setChangeCount={setChangeCount}
        formData={formData}
      />
    </div>
  );
};

export default Form;
