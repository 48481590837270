import React, { useEffect, useRef, useState } from 'react';
import BackButton from 'components/BackButton';
import MyDatePicker from 'components/DatePicker';

import SaveButton from 'components/SaveButton';
import TextArea from 'components/TextArea';
import SearchRede from 'components/SearchRede';
import TimerRascunho from 'components/TimerRascunho';
import { flushSync } from 'react-dom';
import InvestimentoJBP from 'components/InvestimentoJBP';
import UploadMedia from 'components/UploadMedia';

const Form = ({ formData, setFormData, nextTab, tipoArquivoList }) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();
  const fileIdCounter = useRef(formData.arquivos.length || 0);

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }

    if (formData?.updateFileIdCounter) {
      fileIdCounter.current = formData.arquivos.length;
      setFormData({ ...formData, updateFileIdCounter: false });
    }
  }, [formData]);

  const validacaoFormulario = async () => {
    let todosErros = [];

    // validação de campos obrigatorios
    !formData.redeId && todosErros.push('Rede');
    !formData.inicio && todosErros.push('Inicio do Período da Ação');
    !formData.fim && todosErros.push('Fim do Período da Ação');
    !formData.investimento && todosErros.push('Investimento');
    !formData.resultados && todosErros.push('Aprendizados e Pontos de Melhoria');

    const listaZerada = !formData.arquivos || formData.arquivos.length == 0;
    listaZerada && todosErros.push('Arquivos Vazios');

    const arquivosEmpty = formData.arquivos.filter(
      (item) => item.type == 0 && !(item.file || item.urlArquivo)
    );
    arquivosEmpty.length && todosErros.push('Arquivos');

    const arquivosWithFile = formData.arquivos.filter(
      (item) => item.type > 0 && !(item.file || item.urlArquivo)
    );
    arquivosWithFile.length && todosErros.push('Carregar Imagem Arquivos');

    const arquivosWithoutType = formData.arquivos.filter(
      (item) => item.type == 0 && (item.file || item.urlArquivo)
    );
    arquivosWithoutType.length && todosErros.push('Arquivos Sem Tipo');

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeFile = (file, id) => {
    window.showLoader();
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return { ...item, urlArquivo: file };
      } else {
        return item;
      }
    });

    window.hideLoader();

    setFormData({ ...formData, arquivos: arquivos });
  };

  const onChangeType = (typeSelected, id) => {
    window.showLoader();

    var arquivo = formData.arquivos.find((item) => item.id === id);
    var newType = tipoArquivoList.find((item) => item.value === typeSelected);

    if (arquivo?.type === 0 && newType.isLink) {
      arquivo.urlArquivo = '';
    }

    if (arquivo?.type !== 0 && arquivo?.type !== typeSelected) {
      var oldType = tipoArquivoList.find((item) => item.value === arquivo.type);
      if (oldType.isLink !== newType.isLink) {
        arquivo.urlArquivo = '';
      }
    }

    arquivo.type = typeSelected;
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return arquivo;
      } else {
        return item;
      }
    });

    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const addFileFunc = () => {
    window.showLoader();
    const arquivos = [...formData.arquivos, { id: fileIdCounter.current++, type: 0 }];
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const removeFileFunc = (id) => {
    window.showLoader();
    const arquivos = formData.arquivos.filter((item) => item.id !== id);
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const changeLinkItemFunc = (id, urlNova) => {
    const newArquivos = formData.arquivos.map((item) =>
      item.id === id ? { ...item, urlArquivo: urlNova } : item
    );
    setFormData({ ...formData, arquivos: newArquivos });
  };

  return (
    <div className="row">
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo <b>{value}</b> é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>
      <SearchRede changeRede={setFormData} formData={formData} campoObrigatorio />
      <h5>Período da Ação</h5>
      <div className="col-6">
        <MyDatePicker
          placeholderText="Data Inicial"
          selected={formData.inicio}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, inicio: value })}
        />
      </div>
      <div className="col-6">
        <MyDatePicker
          placeholderText="Data Final"
          selected={formData.fim}
          minDate={formData.inicio}
          disabled={!formData.inicio}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, fim: value })}
        />
      </div>
      <InvestimentoJBP
        formData={formData}
        setFormData={setFormData}
        currency={true}
        label="Investimento Total (em 100% das lojas)"
      />
      {/* Aprendizados e Pontos de Melhoria ------------------------------------------------------------------------------------------- */}
      <div className="col-12">
        <TextArea
          placeholder="Descreva os aprendizados e pontos de melhoria percebidos durante a ativação. Máximo 160 caracteres."
          label="Aprendizados e Pontos de Melhoria"
          row="5"
          col="160"
          max={160}
          value={formData.resultados}
          campoObrigatorio
          onChange={(e) => {
            if (e.target.value === undefined || e.target.value.length <= 160) {
              setFormData({
                ...formData,
                resultados: e.target.value
              });
            } else {
              setFormData({
                ...formData,
                resultados: e.target.value.substring(0, 160)
              });
            }
          }}
        />
      </div>
      {formData.arquivos && (
        <UploadMedia
          arquivos={formData.arquivos}
          addFileFunc={addFileFunc}
          onChangeFile={onChangeFile}
          onChangeType={onChangeType}
          removeFile={removeFileFunc}
          evidenciaId={formData.evidenciaId}
          listTypes={tipoArquivoList}
          changeLinkItemFunc={changeLinkItemFunc}
        />
      )}
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              flushSync(() => {
                setChangeCount(0);
              });
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
      </div>
      <TimerRascunho
        changeCount={changeCount}
        setChangeCount={setChangeCount}
        formData={formData}
      />
    </div>
  );
};

export default Form;
