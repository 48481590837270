import React from 'react';
import RadioBox from 'components/RadioBox';
import InputCurrency from 'components/InputCurrency';
import InputDecimal from 'components/InputDecimal';

// Espaço
// Encartes
// Digital ✅✅
// Mídias Indoor ✅✅

const InvestimentoJBP = ({ formData, setFormData, label = 'Investimento', currency = true }) => {
  return (
    <div className="row">
      <div className="col-4">
        <RadioBox
          label={`A ativação está contemplada no JBP?`}
          name="investimentoRadio"
          selected={formData.investimentoJBPRadio}
          options={[
            { label: 'SIM', value: true },
            { label: 'NÃO', value: false }
          ]}
          onChange={(e) => {
            e === true
              ? setFormData({ ...formData, investimento: -1, investimentoJBPRadio: e })
              : setFormData({ ...formData, investimento: 0, investimentoJBPRadio: e });
          }}
        />
      </div>
      {!formData.investimentoJBPRadio && (
        <div className="col-8">
          {currency && (
            <InputCurrency
              value={formData.investimento}
              campoObrigatorio
              disabled={formData.investimentoJBPRadio}
              onChange={(value) => setFormData({ ...formData, investimento: value })}
              label={label}
            />
          )}
          {!currency && (
            <InputDecimal
              value={formData.investimento}
              campoObrigatorio
              disabled={formData.investimentoJBPRadio}
              onChange={(value) => setFormData({ ...formData, investimento: value })}
              label={label}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InvestimentoJBP;
