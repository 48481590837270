import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo';
import Button from 'components/Button';

import './style.scss';

// eslint-disable-next-line no-undef
const returnUrl = process.env.REACT_APP_URL_ACESSO;

const Navbar = ({ nome }) => {
  return (
    <header className="ps-4 pe-4 py-1 mb-1 border-bottom navbar">
      <div className="container-fluid d-grid gap-4 align-items-center navbar-grid">
        <Link to="/">
          <Logo />
        </Link>
        <h4>RELATÓRIO DE EVIDÊNCIAS ON / OFF</h4>
        <p>{nome ?? ''}</p>
        <Button
          color="white"
          className="btn-light"
          onClick={() => {
            window.location = `${returnUrl}`;
          }}>
          <span className="text-secondary">Voltar para o Mais que Imagens</span>
        </Button>
      </div>
    </header>
    // <div>Navbar</div>
  );
};

export default Navbar;
