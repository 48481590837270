import React from 'react';
import { Outlet } from 'react-router-dom';

import AppTitle from 'components/AppTitle';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

const Layout = () => {
  return (
    <div className="layout">
      <Navbar />
      <div className="container">
        <AppTitle>RELATÓRIO DE EVIDÊNCIAS ON / OFF</AppTitle>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
