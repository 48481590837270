/* eslint-disable */
import api, { apiFormData } from 'api';

const PATH = '/Pptx';

export const GET_PPTX = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-pptx-bytes`, {
    params: filtros,
  });
  return data;
};

export const GET_RELATORIO_ENVIO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-relatorioenvio-bytes`, {
    params: filtros,
  });
  return data;
};

export const GET_FECHAMENTO_LIST = async () => {
  const { data } = await api.get(`${PATH}/get-fechamento-list`);
  return data;
};

export const GET_REGIONAL_LIST = async () => {
  const { data } = await api.get(`${PATH}/get-regional-list`);
  return data;
};

export const GET_EVIDENCIATIPO_LIST = async () => {
  const { data } = await api.get(`${PATH}/get-evidenciatipo-list`);
  return data;
};

export const GET_EVIDENCIAPILAR_LIST = async (mes) => {
  const { data } = await api.get(`${PATH}/get-evidenciapilar-list`, { params: { mes } });
  return data;
};
