import api, { apiFormData } from 'api';

const PATH = '/Evidencia';

class Evidencia {
  static async GetHistorico() {
    const { data } = await api.get(`${PATH}/historico`);
    return data;
  }
  static async GetImagensRegional(dataEnvio, pilarId, redeId) {
    let queryParam = ``;
    queryParam += `?dataEnvio=${dataEnvio ?? ''}`;
    queryParam += `&pilarId=${pilarId}`;
    queryParam += `&redeId=${redeId}`;
    const { data } = await api.get(`${PATH}/get-imagens-regional${queryParam}`);

    return data;
  }
  static async GetListRede(nome) {
    const { data } = await api.get(`${PATH}/list-rede?Nome=${nome}`);
    return data;
  }
  static async GetListLoja(nome) {
    const { data } = await api.get(`${PATH}/list-loja?Loja=${nome}`);
    return data;
  }
  static async GetListPilar() {
    const { data } = await api.get(`${PATH}/list-pilar`);
    return data;
  }
  static async GetListMaterial() {
    const { data } = await api.get(`${PATH}/list-material`);
    return data;
  }
  static async GetListCategoria() {
    const { data } = await api.get(`${PATH}/list-categoria`);
    return data;
  }
  static async GetListMarca() {
    const { data } = await api.get(`${PATH}/list-marca`);
    return data;
  }
  static async GetListFamilia() {
    const { data } = await api.get(`${PATH}/list-familia`);
    return data;
  }
  static async GetListProduto() {
    const { data } = await api.get(`${PATH}/list-produto`);
    return data;
  }
  static async GetListTipoArquivo() {
    const { data } = await api.get(`${PATH}/list-tipo-arquivo`);
    return data;
  }
  static async GetListAtivacaoScanntech(filtro) {
    const { data } = await api.get(`${PATH}/list-ativacao-scanntech`, { params: filtro });
    return data;
  }
  static async PostUploadRedeLogo(dataBody) {
    const { data } = await apiFormData.post(`${PATH}/upload-rede-logo`, dataBody);
    return data;
  }
  static async PostUploadLojaLogo(dataBody) {
    const { data } = await apiFormData.post(`${PATH}/upload-loja-logo`, dataBody);
    return data;
  }
}

export default Evidencia;
