import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutLogin from 'components/LayoutLogin';
import Layout from 'components/Layout';
import LoginPage from 'pages/Login';
import Sucess from 'pages/Sucess';

import Home from 'pages/Home';
import HistoricoEvidencias from 'pages/HistoricoEvidencias';
import EvidenciasRegional from 'pages/HistoricoEvidencias/EvidenciasRegional';

import Eventos from 'pages/Eventos';
import Visibilidade from 'pages/Visibilidade';

import BaixarRelatorios from 'pages/BaixarRelatorios';
import DashboardResumo from 'pages/DashboardResumo';
import Erro from 'pages/Erro';
import Rascunhos from 'pages/Rascunhos';

import AcoesLmpm from 'pages/Ativacoes/AcoesLmpm';
import Encartes from 'pages/Ativacoes/Encartes';
import Espaco from 'pages/Ativacoes/Espaco';
import Digital from 'pages/Ativacoes/Digital';
// import Scanntech from 'pages/Ativacoes/Scanntech';

import { GlobalProvider } from 'context';
import LayoutLoginUntitled from 'components/LayoutLoginUntitled';
import EvidenciasExtras from 'pages/EvidenciasExtras';

const AppRoute = () => {
  return (
    // <AuthProvider>
    <BrowserRouter>
      <Routes>
        {/*
          Layout após login e
          Validação de login
        */}
        <Route element={<GlobalProvider />}>
          <Route element={<LayoutLoginUntitled />}>
            <Route path="meus-rascunhos" element={<Rascunhos />} />
          </Route>
          <Route element={<LayoutLogin />}>
            <Route exact path="/" element={<Home />} />

            <Route path="/ativacoes">
              {/* <Route index element={<Ativacoes />} /> */}

              <Route path="acoes-lmpm" element={<AcoesLmpm />}>
                <Route path=":id" element={<AcoesLmpm />} />
                <Route path=":rascunho/:id" element={<AcoesLmpm />} />
              </Route>

              <Route path="encartes" element={<Encartes />}>
                <Route path=":id" element={<Encartes />} />
                <Route path=":rascunho/:id" element={<Encartes />} />
              </Route>

              <Route path="espaco" element={<Espaco />}>
                <Route path=":id" element={<Espaco />} />
                <Route path=":rascunho/:id" element={<Espaco />} />
              </Route>

              {/* <Route path="scanntech" element={<Scanntech />}>
                <Route path=":id" element={<Scanntech />} />
                <Route path=":rascunho/:id" element={<Scanntech />} />
              </Route> */}

              <Route path="digital" element={<Digital />}>
                <Route path=":id" element={<Digital />} />
                <Route path=":rascunho/:id" element={<Digital />} />
              </Route>

              <Route path="eventos" element={<Eventos />}>
                <Route path=":id" element={<Eventos />} />
                <Route path=":rascunho/:id" element={<Eventos />} />
              </Route>

              <Route path="visibilidade" element={<Visibilidade />}>
                <Route path=":id" element={<Visibilidade />} />
                <Route path=":rascunho/:id" element={<Visibilidade />} />
              </Route>
            </Route>

            <Route path="/evidencias-extras" element={<EvidenciasExtras />}>
              <Route path=":id" element={<EvidenciasExtras />} />
              <Route path=":rascunho/:id" element={<EvidenciasExtras />} />
            </Route>

            <Route path="/historico-evidencias" element={<HistoricoEvidencias />} />
            <Route path="/evidencias-regional" element={<EvidenciasRegional />} />

            <Route path="/baixar-relatorios" element={<BaixarRelatorios />} />
            <Route path="/dashboard-resumo" element={<DashboardResumo />} />
            <Route path="/sucesso" element={<Sucess />} />
          </Route>
        </Route>
        {/*
          Layout páginas sem validação de login
        */}
        <Route element={<Layout />}>
          <Route path="*" element={<Erro />} />
          <Route path="/erro" element={<Erro />} />
          <Route path="/acesso" element={<Erro />} />
          <Route path="/login/:token" element={<LoginPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    // </AuthProvider>
  );
};

export default AppRoute;
