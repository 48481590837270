import React from 'react';
import Select from 'react-select';

import './style.scss';
import getCampoObrigatorioClassName from 'helpers/campoObrigatorio';

const SelectMult = ({
  label = 'Selecione',
  placeholder = 'Selecione...',
  options = [],
  isClearable,
  value,
  disabled,
  onChange,
  name,
  maxItem,
  campoObrigatorio = false,
  className = ''
}) => {
  const setMultiSelectedValue = function (selectList, values) {
    const data = [];
    values &&
      values.map((mapValue) => {
        const valorEncontrado = selectList.find((opt) => {
          return mapValue == parseInt(opt.value, 10);
        });
        if (valorEncontrado) {
          data.push(valorEncontrado);
        }
      });

    return data;
  };
  return (
    <div className="mb-3">
      <label>{label}</label>
      <Select
        isMulti
        closeMenuOnSelect={false}
        options={options}
        name={name}
        value={setMultiSelectedValue(options, value)}
        className={`${className} ${
          campoObrigatorio && getCampoObrigatorioClassName(value?.length)
        }-select`}
        classNamePrefix="mySelect"
        onChange={(item) => {
          const newValue =
            item && item.length !== null
              ? item.map((value) => {
                  return parseInt(value.value, 10);
                })
              : '';
          onChange(newValue);
        }}
        placeholder={placeholder}
        isDisabled={disabled}
        isClearable={isClearable}
        isOptionDisabled={() => value && value.length >= maxItem}
      />
    </div>
  );
};

export default SelectMult;
