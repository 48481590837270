import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import React from 'react';
import { Table } from 'react-bootstrap';
import DisplayImageExtra from 'components/DisplayImageExtra';

const Resume = ({ backTab, saveForm, formData }) => {
  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Tipo de Ativação</b>
            </td>
            <td className="col-8">Evidências Extras</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Rede</b>
            </td>
            <td className="col-8">{formData.rede?.nome}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Descrição da Ação</b>
            </td>
            <td className="col-8" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
              {formData.descricao}
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="row">
        <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
        <DisplayImageExtra arquivos={formData.arquivos} />
      </div>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
