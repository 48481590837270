import React from 'react';
import './style.scss';

const RadioBox = ({ name, label, options = [], onChange, selected }) => {
  const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  };
  return (
    <div className="mb-3 ContainerRadioBox">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="optionsRadioBox d-flex align-items-center">
        {options &&
          options.map((item) => {
            const id = uniqueId();
            return (
              <div className="form-check form-check-inline" key={item.value}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={item.value}
                  id={`check-${item.value}-${id}`}
                  checked={item.value === selected}
                  onChange={() => onChange(item.value)}
                />
                <label
                  className="form-check-label text-uppercase"
                  htmlFor={`check-${item.value}-${id}`}>
                  {item.label}
                </label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RadioBox;
