import React from 'react';

import './style.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <p className="">@2023</p>
    </footer>
  );
};

export default Footer;
