import React from 'react';
import { NumericFormat } from 'react-number-format';
import getCampoObrigatorioClassName from 'helpers/campoObrigatorio';

const InputDecimal = ({
  label,
  onChange,
  value,
  className,
  disabled = false,
  allowNegative = false,
  campoObrigatorio = false
}) => {
  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <div className="input-group">
        <NumericFormat
          decimalSeparator=","
          thousandSeparator="."
          className={`form-control border-start-1 ${className} ${
            campoObrigatorio && getCampoObrigatorioClassName(value)
          }`}
          onValueChange={(values) => {
            return onChange(values.floatValue);
          }}
          value={value}
          disabled={disabled}
          allowNegative={allowNegative}
        />
      </div>
    </div>
  );
};

export default InputDecimal;
