import React from 'react';
import Select from 'react-select';

import './style.scss';

const SelectInput = ({
  className,
  label = 'Selecione',
  placeholder = 'Selecione...',
  options = [],
  isClearable,
  value,
  disabled,
  onChange,
  name
}) => {
  const setSelectedValue = function (selectList, value) {
    if (!selectList || selectList.length === 0) return false;

    if (!value) return false;

    for (let i = 0; i < selectList.length; i++) {
      const el = selectList[i];

      if (el.value && el.value === value) {
        return el;
      }

      if (el.options && el.options.length > 0) {
        const obj = setSelectedValue(el.options, value);

        if (obj) return obj;
      }
    }

    return false;
  };

  return (
    <div className="mb-3">
      <label style={{ marginBottom: '8px' }}>{label}</label>
      <Select
        className={className}
        isClearable={isClearable}
        options={options}
        value={setSelectedValue(options, value)}
        onChange={(value) => onChange(value == null ? '' : value.value, name)}
        placeholder={placeholder}
        isDisabled={disabled}
      />
    </div>
  );
};

export default SelectInput;
