import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import pt from 'date-fns/locale/pt-BR';

import getCampoObrigatorioClassName from 'helpers/campoObrigatorio';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

registerLocale('pt', pt);

const MyDatePicker = ({
  name,
  disabled,
  selected,
  onChange,
  minDate,
  maxDate,
  className = '',
  label,
  placeholderText,
  campoObrigatorio = false
}) => {
  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={name} className={`form-label `}>
          {label}
        </label>
      )}
      <div className={`date-picker-custom input-group `}>
        <DatePicker
          locale="pt"
          name={name}
          dateFormat="dd/MM/yyyy"
          disabled={disabled}
          selected={selected}
          placeholderText={placeholderText}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(value) => onChange(value, name)}
          className={`form-control border-0 ${className} ${
            campoObrigatorio && getCampoObrigatorioClassName(selected)
          } ${
            campoObrigatorio && getCampoObrigatorioClassName(selected) === 'campo-obrigatorio'
              ? 'border-right-reset'
              : ''
          }`}
          // wrapperClassName={`form-control p-0 border-right-reset border-0`}
          wrapperClassName={`form-control p-0 ${
            campoObrigatorio && getCampoObrigatorioClassName(selected) === 'campo-obrigatorio'
              ? 'border-right-reset border-0'
              : ''
          }`}
        />
        <span
          className={`input-group-text ${
            campoObrigatorio && getCampoObrigatorioClassName(selected)
          }`}>
          <FontAwesomeIcon icon={faCalendar} />
        </span>
      </div>
    </div>
  );
};

export default MyDatePicker;
