import React from 'react';
import PageTitle from 'components/PageTitle';

function DashboardResumo() {
  return (
    <div>
      <PageTitle>Dashboard Resumo</PageTitle>
    </div>
  );
}

export default DashboardResumo;
