import React, { useEffect, useState } from 'react';
import PageTitle from 'components/PageTitle';
import FiltroEvidencias from './FiltroEvidencia';

import Evidencia from 'services/Evidencia';

import './style.scss';
import { dateFormat, JsDateToCsDateStr } from 'helpers/Date';

function EvidenciasRegional() {
  const [imagens, setImagens] = useState([]);

  useEffect(() => {
    getImagens(null, 0, 0);
  }, []);

  async function getImagens(dataEnvio, pilarId, rede) {
    window.showLoader();
    let dataEnvioFomatado = '';
    if (dataEnvio && dataEnvio !== '') {
      dataEnvioFomatado = JsDateToCsDateStr(dataEnvio);
    }
    const data = await Evidencia.GetImagensRegional(dataEnvioFomatado, pilarId, rede?.redeId ?? 0);
    setImagens(data?.value ?? []);
    window.hideLoader();
  }

  return (
    <div className="EvidenciasRelatorio">
      <PageTitle>EVIDÊNCIAS DA REGIONAL</PageTitle>
      <h5 className="text-center">
        Acompanhe abaixo a lista de evidências se sua regional. Caso necessário, utilize os filtros
        para filtrar as evidências mostradas:
      </h5>
      <FiltroEvidencias onFiltrar={getImagens}></FiltroEvidencias>
      {imagens.length == 0 ? (
        <h5 className="text-center">Nenhuma evidência encontrada</h5>
      ) : (
        <div className="row image-display">
          {imagens.map((imagem, index) => (
            <div className="col-sm-3 text-center" key={index}>
              <img
                src={imagem.urlMiniatura}
                style={{ maxHeight: '100%', padding: '15px' }}
                className="img-fluid"
                alt={'imagem-' + index}
              />
              <div>{imagem.rede ?? 'Sem Rede'}</div>
              <div>{dateFormat(imagem.data)}</div>
              <div>{imagem.usuario}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EvidenciasRegional;
