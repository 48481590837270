/* eslint-disable no-undef */
import React, { useState } from 'react';
import { uploadFile } from 'react-s3';
import { v4 as uuidv4 } from 'uuid';

import Loading from './loading.svg';

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  dirName: process.env.REACT_APP_S3_DIR_NAME,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
};

const InputUploadS3 = ({ setItemUrl, evidenciaId, fontSizeLabel }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const styleFontSize = fontSizeLabel && fontSizeLabel > 0 ? fontSizeLabel + 'px' : '16px';

  window.Buffer = window.Buffer || require('buffer').Buffer;

  const handleUpload = async (e) => {
    e.preventDefault();

    if (e.target.files[0]) {
      setLoading(true);
      const regexExtension = /\.[0-9a-z]+$/i;
      const nome = e.target.files[0].name;
      const newName = `${nome.replace(/.+?\\(?=\w+)|\.\w+$|\\$/gm, '')}-${uuidv4()}${
        nome.match(regexExtension)[0]
      }`;
      const file = new File([e.target.files[0]], newName);
      setSelectedFile({ nomeReal: nome, ...e.target.files[0] });
      // const fileSize = e.target.files[0].size;
      uploadFile(file, { ...config, dirName: config.dirName + '/' + evidenciaId })
        .then((data) => {
          const newUrl = data.location;
          setLoading(false);
          setItemUrl(newUrl);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className="d-flex justify-content-center flex-column p-2 pb-0">
      <label className="btn btn-primary px-4" style={{ fontSize: `${styleFontSize}` }}>
        Faça upload do arquivo
        <input
          id="itemAltaDefinicao"
          className="input-file d-none"
          name="itemAltaDefinicao"
          type="file"
          onChange={(e) => {
            handleUpload(e);
          }}
        />
      </label>
      {/* <div className="file-name text-center pt-2">{selectedFile ? selectedFile.nomeReal : ''}</div> */}
      <div className="text-center">
        {loading ? <img src={Loading} alt="Loading" style={{ width: '40px' }} /> : ''}
      </div>
      {!loading && selectedFile ? (
        <h5 className="text-center mb-0 pt-2" style={{ fontSize: `${styleFontSize}` }}>
          <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true"></i>
          Arquivo enviado com sucesso!
        </h5>
      ) : (
        ''
      )}
    </div>
  );
};

export default InputUploadS3;
