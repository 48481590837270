import React, { useState, useEffect, useRef } from 'react';

import SearchRede from 'components/SearchRede';

import TextArea from 'components/TextArea';
import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import { flushSync } from 'react-dom';
import TimerRascunho from 'components/TimerRascunho';
import UploadImageEvidenciaExtra from 'components/UploadImageEvidenciaExtra';

const Form = ({ nextTab, formData, setFormData }) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();
  const fileIdCounter = useRef(formData.arquivos.length || 0);

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }

    if (formData?.updateFileIdCounter) {
      fileIdCounter.current = formData.arquivos.length;
      setFormData({ ...formData, updateFileIdCounter: false });
    }
  }, [formData]);

  const validacaoFormulario = async () => {
    let todosErros = [];
    // validação de campos obrigatorios
    !formData.redeId && todosErros.push('Rede');

    !formData.descricao && todosErros.push('Descrição');

    const arquivosWithoutFile = formData.arquivos.filter((item) => !(item.file || item.urlArquivo));
    arquivosWithoutFile.length && todosErros.push('Arquivos');

    const arquivosWithLegend = formData.arquivos.filter(
      (item) => (item.file || item.urlArquivo) && (!item.legenda || item.legenda == '')
    );
    arquivosWithLegend.length && todosErros.push('Arquivos Sem Legenda');

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeFile = (file, id) => {
    window.showLoader();
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return { ...item, urlArquivo: file };
      } else {
        return item;
      }
    });

    window.hideLoader();

    setFormData({ ...formData, arquivos: arquivos });
  };

  const changeLegendaFunc = (id, legendaNova) => {
    const newArquivos = formData.arquivos.map((item) =>
      item.id === id ? { ...item, legenda: legendaNova } : item
    );
    setFormData({ ...formData, arquivos: newArquivos });
  };

  const addFileFunc = () => {
    window.showLoader();
    const arquivos = [...formData.arquivos, { id: fileIdCounter.current++, legenda: '' }];
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const removeFileFunc = (id) => {
    window.showLoader();
    const arquivos = formData.arquivos.filter((item) => item.id !== id);
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  return (
    <>
      {/* Validacoes -------------------------------------------------------------------------------------------------------- */}
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo {value} é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>

      {/* Busca Rede -------------------------------------------------------------------------------------------------------- */}
      <SearchRede
        changeRede={setFormData}
        formData={formData}
        value={formData.redeId}
        campoObrigatorio
      />

      {/* Descrição -------------------------------------------------------------------------------------------------------- */}
      <div className="col-12">
        <TextArea
          placeholder="Descreva os aprendizados e pontos de melhoria percebidos durante a ativação. Máximo 750 caracteres."
          label="Descrição da Ação"
          row="5"
          col="160"
          max={750}
          campoObrigatorio
          value={formData?.descricao}
          height="230px"
          onChange={(e) => {
            if (e.target.value === undefined || e.target.value.length <= 750) {
              setFormData({
                ...formData,
                descricao: e.target.value
              });
            } else {
              setFormData({
                ...formData,
                descricao: e.target.value.substring(0, 750)
              });
            }
          }}
        />
      </div>

      <div className="col-12">
        {/* Imagens ----------------------------------------------------------------------------------------------------------- */}
        {formData.arquivos && (
          <UploadImageEvidenciaExtra
            arquivos={formData.arquivos}
            onChangeFile={onChangeFile}
            evidenciaId={formData.evidenciaId}
            addFileFunc={addFileFunc}
            removeFile={removeFileFunc}
            changeDescription={changeLegendaFunc}
          />
        )}
      </div>

      {/* Botoes Voltar e Salvar -------------------------------------------------------------------------------------------- */}
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              flushSync(() => {
                setChangeCount(0);
              });
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
      </div>
      <TimerRascunho
        changeCount={changeCount}
        setChangeCount={setChangeCount}
        formData={formData}
      />
    </>
  );
};

export default Form;
