import api from 'api';

const PATH = '/OnOffSolicitacao';

class Solicitacao {
  static async GetSolicitacoesUsuarioFechamento() {
    const { data } = await api.get(`${PATH}/get-solicitacoes-usuario-fechamento`);
    return data.value;
  }
}

export default Solicitacao;
