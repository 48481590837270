import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import EvidenciaAtivacaoEncarte from 'services/EvidenciaAtivacaoEncarte';

import Button from 'components/Button';
import Solicitacao from 'services/Solicitacao';
import SemFoto from 'assets/imgs/SEMFOTO.png';
import { getDateFromStringJson } from 'helpers/Date';

import './style.scss';

const SearchSolicitacao = ({ changeSolicitacao, formData }) => {
  const [show, setShow] = useState(false);
  const [filtro, setFiltro] = useState('');
  const [filtro_, setFiltro_] = useState('');
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState({});
  const [errorText, setErroText] = useState('');
  const [modalErro, setModalErro] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);

  useEffect(() => {
    (async () => {
      window.showLoader();
      if (filtro_) {
        const data = await EvidenciaAtivacaoEncarte.getSolicitacao(parseInt(filtro_));
        if (data.value) {
          setSelectedItem(data.value);
          setModalConfirm(true);
        }
        if (data.errors && data.errors['solicitacao'].length) {
          setModalErro(true);
          setErroText(data.errors['solicitacao']);
        }
      }
      window.hideLoader();
    })();
  }, [filtro_]);

  const handleClose = () => setShow(false);

  const handleShow = async () => {
    window.showLoader();
    if (filtro) {
      const data = await EvidenciaAtivacaoEncarte.getSolicitacao(parseInt(filtro));
      if (data.value) {
        setSelectedItem(data.value);
        setModalConfirm(true);
      }
      if (data.errors && data.errors['solicitacao'].length) {
        setModalErro(true);
        setErroText(data.errors['solicitacao']);
      }
    }
    window.hideLoader();
  };

  const handleShow2 = async () => {
    window.showLoader();
    setSolicitacaoSelecionada({});
    const data = await Solicitacao.GetSolicitacoesUsuarioFechamento();
    setSolicitacoes(data);
    setShow(true);
    window.hideLoader();
  };

  const changeNewSolicitacao = () => {
    if (solicitacaoSelecionada.solicitacaoId) {
      setFiltro(String(solicitacaoSelecionada.solicitacaoId));
      setFiltro_(String(solicitacaoSelecionada.solicitacaoId));
      changeSolicitacao({
        ...formData,
        solicitacaoId: solicitacaoSelecionada.solicitacaoId,
        solicitacao: solicitacaoSelecionada
      });
    }
    handleClose();
  };

  const handleCloseModalErro = () => {
    setModalErro(false);
    setErroText('');
  };

  const retornaDadosSolicitacao = async () => {
    window.showLoader();
    if (selectedItem) {
      const cardCount = 4; // Numero de cards para imagens na pagina

      // Pega os arquivos de arte
      let arquivoArtes = selectedItem.arquivos.filter(
        (x) =>
          x.categoria == 'ARTE' ||
          x.categoria == 'FRENTE' ||
          x.categoria == 'VERSO' ||
          x.categoria == 'MATENTRG'
      );

      // Mapeia os arquivos de arte no model esperado pelo backend
      let arquivos = arquivoArtes?.map((item, index) => {
        return { id: index, file: null, urlArquivo: null, urlS3: item.url };
      });

      // Se o numero de arquivos eh menor que o numero de cards
      // cria arquivos vazios para os cards restantes.
      arquivos = arquivos ?? [];
      let imageCount = arquivos?.length ?? 0;
      for (let i = imageCount; i < cardCount; i++) {
        arquivos.push({ id: i });
      }

      // Atualiza os dados da solicitacao
      changeSolicitacao({
        ...formData,
        solicitacao: {
          ...selectedItem,
          inicio: getDateFromStringJson(selectedItem.inicio),
          fim: getDateFromStringJson(selectedItem.fim)
        },
        arquivos: arquivos
      });
    }
    setModalConfirm(false);
    window.hideLoader();
  };

  return (
    <>
      {/* Numero da Solicitacao --------------------------------------------------------------------------------------------- */}
      <div className="row">
        <div className="col-6">
          <label className="form-label">Solicitação</label>
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Número da Solicitação"
              className="form-control"
              style={{ minHeight: '47.6px' }}
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
            />
            <button type="button" className="btn btn-primary px-4" onClick={handleShow}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>

        <div className="col-6">
          <label className="form-label">&nbsp;</label>
          <div className="input-group mb-3">
            <button
              type="button"
              className="btn btn-primary "
              onClick={handleShow2}
              style={{ minHeight: '47.6px', minWidth: '-webkit-fill-available' }}>
              Selecionar Solicitação
            </button>
          </div>
        </div>
      </div>

      {/* Modal ------------------------------------------------------------------------------------------------------------- */}
      <Modal show={show} onHide={handleClose} className="rede-modal modal-lg">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>Selecione a solicitação e clique em confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row border border-1 p-2 my-1 modal-rede-list">
            {solicitacoes.length
              ? solicitacoes.map((item) => {
                  let data = getDateFromStringJson(item.data).toLocaleDateString();
                  let urlArte = item.urlArte ?? item.urlFrente;
                  return (
                    <div
                      className={`col-12 ${
                        solicitacaoSelecionada.solicitacaoId === item.solicitacaoId &&
                        'item-selecionado'
                      }`}
                      key={item.solicitacaoId}
                      onClick={
                        () =>
                          setSolicitacaoSelecionada(
                            solicitacaoSelecionada.solicitacaoId !== item.solicitacaoId ? item : {}
                          )
                        //setSolicitacaoSelecionada(item ? item : {})
                      }>
                      <div className="row py-2 rede-item">
                        <div className="col-4">
                          <img
                            src={urlArte ? urlArte : SemFoto}
                            alt={item.solicitacaoId}
                            className="w-100"
                          />
                        </div>
                        <div className="col-2 d-flex justify-content-start align-items-center ps-4">
                          {item.solicitacaoId}
                        </div>
                        <div className="col-4 d-flex justify-content-start align-items-center ps-4">
                          {item.rede}
                        </div>
                        <div className="col-2 d-flex justify-content-start align-items-center ps-4">
                          {data}
                        </div>
                      </div>
                    </div>
                  );
                })
              : 'Nenhuma solicitação encontrada'}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="ms-2 p-2 fw-semibold"
            width="320px"
            onClick={() => changeNewSolicitacao()}>
            SELECIONAR
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal confirmação ------------------------------------------------------------------------------------------------- */}
      <Modal show={modalConfirm} onHide={handleClose} size="lg">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>SOLICITAÇÃO {selectedItem.solicitacaoId}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            <p className="h4 text-center">
              Confira a arte da solicitação e confirme abaixo caso esta for a solicitação que está
              buscando, Caso não seja, clique em cancelar e pesquise novamente pelo número da
              solicitação correto.
            </p>
            <p className="text-center p-3">Razão Social: {selectedItem.razaoSocial}</p>
            <div className="text-center">
              {selectedItem.arquivos && selectedItem.arquivos.length
                ? selectedItem.arquivos
                    .filter(
                      (x) =>
                        x.categoria == 'ARTE' ||
                        x.categoria == 'FRENTE' ||
                        x.categoria == 'VERSO' ||
                        x.categoria == 'MATENTRG'
                    )
                    .map((item, index) => (
                      <img className="img-fluid" src={item.url} key={index} alt={`img-${index}`} />
                    ))
                : ''}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="danger"
            className="ms-2 p-2 fw-semibold"
            width="320px"
            onClick={() => {
              setModalConfirm(false);
              setFiltro('');
              setFiltro_('');
            }}>
            CANCELAR
          </Button>
          <Button
            className="ms-2 p-2 fw-semibold"
            width="320px"
            onClick={() => retornaDadosSolicitacao()}>
            CONFIRMAR
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal erro  ------------------------------------------------------------------------------------------------------- */}
      <Modal show={modalErro} onHide={handleCloseModalErro} size="lg">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>SOLICITAÇÃO {filtro}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            <p className="h4 text-center">{errorText}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="ms-2 p-2 fw-semibold"
            width="320px"
            onClick={() => setModalErro(false)}>
            FECHAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchSolicitacao;
