import { useEffect, useRef, useCallback } from 'react';
import { flushSync } from 'react-dom';
import Rascunho from 'services/Rascunho';

const TimerRascunho = ({ changeCount, setChangeCount, formData, intervalo = 20000 }) => {
  const refreshRef = useRef();

  refreshRef.current = useCallback(async () => {
    if (changeCount > 0) {
      flushSync(async () => {
        setChangeCount(0);
      });
      const formDataString = JSON.stringify(formData);
      await Rascunho.PostSalvarRascunho(formDataString, formData.evidenciaId);
    }
  }, [changeCount]);

  // const [intervalId, setIntervalId] = useState();
  let interval;

  // const saveResume = async () => {
  //   clearInterval(intervalId);
  //   setChangeCount(0);
  //   const formDataString = JSON.stringify(formData);
  //   await Rascunho.PostSalvarRascunho(formDataString, formData.evidenciaId);
  // }

  useEffect(() => {
    refreshRef.current();
    interval = setInterval(() => refreshRef.current(), intervalo);
    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default TimerRascunho;
