import React from 'react';

const DisplayImageType = ({ arquivos, tipoArquivoList }) => {
  return (
    <>
      {arquivos
        .filter((item) => item.urlArquivo?.length > 0 || item.urlS3?.length > 0)
        .map((item, index) => {
          const url = item.urlS3 || item.urlArquivo;
          return (
            <div className="col-3 p-2" key={item.id}>
              <div
                className="p-2"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: '#f3f3f3',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                }}>
                <span>{tipoArquivoList.find((x) => x.value == item.type)?.label ?? ''}</span>
                <br />
                {tipoArquivoList.find((x) => x.value == item.type)?.isLink && (
                  <>
                    <img
                      key={index}
                      src={tipoArquivoList.find((x) => x.value == item.type)?.urlImagem}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                    <span>{item.urlArquivo}</span>
                  </>
                )}
                {(!tipoArquivoList.find((x) => x.value == item.type)?.isLink ||
                  tipoArquivoList.find((x) => x.value == item.type)?.isLink == false) && (
                  <img
                    key={index}
                    src={url}
                    style={{ maxHeight: '240px' }}
                    className="img-fluid"
                    alt="imagem"
                  />
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default DisplayImageType;
