import api from 'api';

class Login {
  static async LoginWithToken(token) {
    const { data } = await api.post(`/login-with-token`, token);
    return data;
  }

  static async LoginWithTokenTeste(token) {
    const { data } = await api.post(`/login-with-token-teste`, token);
    return data;
  }

  static async GetUser() {
    const { data } = await api.post(`/get-user`);
    return data;
  }
}

export default Login;
