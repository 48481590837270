import React from 'react';

const Button = ({ children, color = 'primary', className, maxWidth = 'auto', width, onClick }) => {
  return (
    <>
      <button
        type="button"
        className={`btn btn-${color} ${className}`}
        width={width}
        style={{ borderRadius: '20px', maxWidth: maxWidth, width: width }}
        onClick={onClick}>
        {children}
      </button>
    </>
  );
};

export default Button;
