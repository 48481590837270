import React from 'react';

import './style.scss';

const PageTitle = ({ children, subTitle = '' }) => {
  return (
    <div className="pageTitle pt-4 pb-4">
      <h2 className="texto">{children}</h2>
      {subTitle && <h4>{subTitle}</h4>}
    </div>
  );
};

export default PageTitle;
