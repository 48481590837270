import React, { useState, useEffect, useRef } from 'react';

import SearchRede from 'components/SearchRede';
import Input from 'components/Input';

import InputCurrency from 'components/InputCurrency';
import InputDecimal from 'components/InputDecimal';
import TextArea from 'components/TextArea';
import MyDatePicker from 'components/DatePicker';
import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import { flushSync } from 'react-dom';
import TimerRascunho from 'components/TimerRascunho';
import UploadImage from 'components/UploadImage';

const Form = ({ nextTab, formData, setFormData }) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();
  const fileIdCounter = useRef(formData.arquivos.length || 0);

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }

    if (formData?.updateFileIdCounter) {
      fileIdCounter.current = formData.arquivos.length;
      setFormData({ ...formData, updateFileIdCounter: false });
    }
  }, [formData]);

  const validacaoFormulario = async () => {
    let todosErros = [];
    // validação de campos obrigatorios
    !formData.redeId && todosErros.push('Rede');
    !formData.qtdLojasTotal && todosErros.push('Qtd. Total de Lojas');
    !formData.qtdLojasAtivadas && todosErros.push('Qtd. Lojas Ativas');
    !formData.mecanicaAcao && todosErros.push('Mecânica da Ação');
    !formData.investimento && todosErros.push('Investimento na ação');

    !formData.inicio && todosErros.push('Inicio do Período da Ação');
    !formData.fim && todosErros.push('Fim do Período da Ação');

    !formData.sellOutInicial && todosErros.push('Sell Out Inicial');
    !formData.sellOutFinal && todosErros.push('Sell Out Final');

    !formData.resultados && todosErros.push('Resultados');

    const arquivosWithFile = formData.arquivos.filter((item) => item.file || item.urlArquivo);
    !arquivosWithFile.length && todosErros.push('Arquivos');

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeFile = (file, id) => {
    window.showLoader();
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return { ...item, urlArquivo: file };
      } else {
        return item;
      }
    });

    window.hideLoader();

    setFormData({ ...formData, arquivos: arquivos });
  };

  const addFileFunc = () => {
    window.showLoader();
    const arquivos = [...formData.arquivos, { id: fileIdCounter.current++ }];
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  const removeFileFunc = (id) => {
    window.showLoader();
    const arquivos = formData.arquivos.filter((item) => item.id !== id);
    window.hideLoader();
    setFormData({ ...formData, arquivos: arquivos });
  };

  return (
    <>
      {/* Validacoes -------------------------------------------------------------------------------------------------------- */}
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo {value} é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>

      {/* Busca Rede -------------------------------------------------------------------------------------------------------- */}
      <SearchRede
        changeRede={setFormData}
        formData={formData}
        value={formData.redeId}
        campoObrigatorio
      />

      {/* Total de Lojas ---------------------------------------------------------------------------------------------------- */}
      <div className="col-6">
        <Input
          name="totalLojas"
          type="number"
          placeholder="00"
          label="Qtd. Total de Lojas"
          min={1}
          campoObrigatorio
          value={formData.qtdLojasTotal || ''}
          onChange={(e) => setFormData({ ...formData, qtdLojasTotal: e.target.value })}
        />
      </div>

      {/* Lojas Ativadas ---------------------------------------------------------------------------------------------------- */}
      <div className="col-6">
        <Input
          name="lojasAtivas"
          type="number"
          placeholder="00"
          label="Qtd. Lojas Ativas"
          min={1}
          campoObrigatorio
          value={formData.qtdLojasAtivadas || ''}
          onChange={(e) => setFormData({ ...formData, qtdLojasAtivadas: e.target.value })}
        />
      </div>

      {/* Mecanica da Acao -------------------------------------------------------------------------------------------------- */}
      <div className="col-12">
        <TextArea
          placeholder="Escreva uma mecânica para a sua ação de até 20 caracteres"
          label="Mecânica da Ação"
          rows="2"
          col="10"
          min={1}
          max={20}
          campoObrigatorio
          value={formData?.mecanicaAcao}
          onChange={(e) => {
            if (e.target.value === undefined || e.target.value.length <= 20) {
              setFormData({
                ...formData,
                mecanicaAcao: e.target.value
              });
            } else {
              setFormData({
                ...formData,
                mecanicaAcao: e.target.value.substring(0, 20)
              });
            }
          }}
        />
      </div>

      {/* Investimento ------------------------------------------------------------------------------------------------------ */}
      <div className="col-6">
        <InputCurrency
          value={formData.investimento}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, investimento: value })}
          label="Investimento na ação"
        />
      </div>

      {/* Periodo da Acao --------------------------------------------------------------------------------------------------- */}
      <h5>Período da Ação</h5>
      <div className="col-6">
        <MyDatePicker
          label="Data Inicial"
          campoObrigatorio
          selected={formData.inicio}
          onChange={(value) => setFormData({ ...formData, inicio: value })}
        />
      </div>
      <div className="col-6">
        <MyDatePicker
          label="Data Final"
          campoObrigatorio
          selected={formData.fim}
          minDate={formData.inicio}
          disabled={!formData.inicio}
          onChange={(value) => setFormData({ ...formData, fim: value })}
        />
      </div>

      {/* Resultados -------------------------------------------------------------------------------------------------------- */}
      <div className="col-12">
        <h4>RESULTADOS</h4>
      </div>
      <div className="col-12">
        <TextArea
          placeholder="Descreva os aprendizados e pontos de melhoria percebidos durante a ativação. Máximo 160 caracteres."
          label="Aprendizados e Pontos de Melhoria"
          row="5"
          col="160"
          max={160}
          campoObrigatorio
          value={formData?.resultados}
          onChange={(e) => {
            if (e.target.value === undefined || e.target.value.length <= 160) {
              setFormData({
                ...formData,
                resultados: e.target.value
              });
            } else {
              setFormData({
                ...formData,
                resultados: e.target.value.substring(0, 160)
              });
            }
          }}
        />
      </div>

      {/* Sell out ---------------------------------------------------------------------------------------------------------- */}
      <div className="col-6">
        <InputDecimal
          label="Sell Out Inicial"
          value={formData?.sellOutInicial}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, sellOutInicial: value })}
        />
      </div>
      <div className="col-6">
        <InputDecimal
          label="Sell Out Final"
          value={formData?.sellOutFinal}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, sellOutFinal: value })}
        />
      </div>

      {/* Imagens ----------------------------------------------------------------------------------------------------------- */}
      {formData.arquivos && (
        <UploadImage
          arquivos={formData.arquivos}
          addFileFunc={addFileFunc}
          onChangeFile={onChangeFile}
          removeFile={removeFileFunc}
          evidenciaId={formData.evidenciaId}
        />
      )}

      {/* Botoes Voltar e Salvar -------------------------------------------------------------------------------------------- */}
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              flushSync(() => {
                setChangeCount(0);
              });
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
      </div>
      <TimerRascunho
        changeCount={changeCount}
        setChangeCount={setChangeCount}
        formData={formData}
      />
    </>
  );
};

export default Form;
