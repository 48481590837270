import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

// import InputFile from 'components/InputFile';
import Button from 'components/Button';

import SemFoto from 'assets/imgs/SEMFOTO.png';
import QualidadeImagem from '../../assets/imgs/dicasQualidade.jpg';

import './styles.scss';
import InputUploadS3 from 'components/InputUploadS3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import TextArea from 'components/TextArea';

const UploadMedia = ({
  arquivos,
  onChangeFile,
  onChangeType,
  removeFile,
  fullWidth = false,
  evidenciaId,
  listTypes = [{ label: 'Selecione um tipo', value: 0 }],
  addFileFunc,
  changeLinkItemFunc
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    window.showLoader();
    setShow(true);
    window.hideLoader();
  };

  return (
    <>
      <div className="uploadImagem">
        <h5 className={`${fullWidth ? 'text-center' : ''} my-3`}>
          {!fullWidth ? (
            <>
              <b>ESCOLHA OS ARQUIVOS</b>{' '}
              <span>
                (Atenção a qualidade de imagens! Consulte o guia{' '}
                <span className="link-primary" onClick={() => handleShow()}>
                  AQUI
                </span>
                )
              </span>
            </>
          ) : (
            <>
              <b>ESCOLHA O ARQUIVO</b>{' '}
            </>
          )}
        </h5>
        <div className="row uploadImage">
          {arquivos?.length > 0 || !fullWidth ? (
            arquivos.map((item, index) => (
              <div className={`${fullWidth ? 'px-5' : 'col-sm-6 col-md-3'} my-2`} key={item.id}>
                <div className="card p-2">
                  <Select
                    isClearable={false}
                    options={listTypes}
                    value={listTypes.find((x) => x.value == item.type)}
                    onChange={(selected) => onChangeType(selected.value, item.id || index)}
                    placeholder={'Selecione o tipo'}
                  />
                  {item.header?.length > 0 ? (
                    <h5 className="px-3 pt-2 mb-0 text-center">{item.header}</h5>
                  ) : null}
                  {(item.type == 0 ||
                    !listTypes.find((x) => x.value == item.type)?.isLink ||
                    listTypes.find((x) => x.value == item.type)?.isLink == false) && (
                    <>
                      <div
                        className="image p-1 d-flex justify-content-center align-items-center"
                        style={{ height: '270px' }}>
                        {item.file?.name ? (
                          <img
                            src={URL.createObjectURL(item.file)}
                            style={{ maxHeight: '240px' }}
                            className="img-fluid"
                            alt="imagem"
                          />
                        ) : item.urlArquivo?.length > 0 ? (
                          <img
                            src={item.urlArquivo}
                            style={{ maxHeight: '240px' }}
                            className="img-fluid"
                            alt="imagem"
                          />
                        ) : item.urlS3?.length > 0 ? (
                          // Imagem de url S3 existente -----------------------------------------------------------------------------
                          <img
                            src={item.urlS3}
                            style={{ maxHeight: '240px' }}
                            className="img-fluid"
                            alt="imagem"
                          />
                        ) : (
                          <img
                            src={SemFoto}
                            style={{ maxHeight: '240px' }}
                            className="img-fluid"
                            alt="imagem"
                          />
                        )}
                      </div>
                      <div className="d-flex justify-content-center flex-column">
                        <InputUploadS3
                          evidenciaId={evidenciaId}
                          setItemUrl={(url) => {
                            onChangeFile(url, item.id || index);
                          }}>
                          Faça upload do arquivo
                        </InputUploadS3>
                      </div>
                      <div className="d-flex justify-content-center pb-2">
                        <Button
                          color="link"
                          className="text-decoration-none"
                          onClick={() => removeFile(item.id)}>
                          <span className="text-danger text-decoration-none">Remover imagem</span>
                        </Button>
                      </div>
                    </>
                  )}
                  {item.type > 0 && listTypes.find((x) => x.value == item.type)?.isLink == true && (
                    <>
                      <div
                        className="image p-1 d-flex justify-content-center align-items-center"
                        style={{ height: '270px' }}>
                        <img
                          src={listTypes.find((x) => x.value == item.type)?.urlImagem}
                          style={{ maxHeight: '240px' }}
                          className="img-fluid"
                          alt="imagem"
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column">
                        <TextArea
                          disableResize
                          placeholder="Digite a URL externa."
                          row="1"
                          max={500}
                          value={item.urlArquivo}
                          onChange={(e) => {
                            changeLinkItemFunc(item.id, e.target.value);
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center pb-2">
                        <Button
                          color="link"
                          className="text-decoration-none"
                          onClick={() => removeFile(item.id)}>
                          <span className="text-danger text-decoration-none">Remover imagem</span>
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className={`${fullWidth ? 'px-5' : 'col-3'} my-2`}>
              <div className="card">
                {arquivos.header?.length > 0 ? (
                  <h5 className="px-3 pt-2 mb-0 text-center">{arquivos.header}</h5>
                ) : null}
                <div
                  className="image p-3 d-flex justify-content-center align-items-center"
                  style={{ height: '270px' }}>
                  {arquivos.file?.name ? (
                    <img
                      src={URL.createObjectURL(arquivos.file)}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  ) : arquivos.urlArquivo?.length > 0 ? (
                    <img
                      src={arquivos.urlArquivo}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  ) : (
                    <img
                      src={SemFoto}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  )}
                </div>
                <div className="d-flex justify-content-center flex-column">
                  <InputUploadS3
                    evidenciaId={evidenciaId}
                    setItemUrl={(url) => {
                      onChangeFile(url);
                    }}>
                    Faça upload do arquivo
                  </InputUploadS3>
                </div>
                <div className="d-flex justify-content-center pb-2">
                  <Button
                    color="link"
                    className="text-decoration-none"
                    onClick={() => removeFile(arquivos.id)}>
                    <span className="text-danger text-decoration-none">Remover imagem</span>
                  </Button>
                </div>
              </div>
            </div>
          )}
          {!fullWidth && (
            <div
              className={`${
                fullWidth ? 'px-5' : 'col-sm-6 col-md-3'
              } my-2 d-flex align-items-center justify-content-center`}
              key={'button-add-more'}>
              <button
                className="btn btn-primary px-4"
                onClick={addFileFunc}
                style={{
                  height: '8rem',
                  width: '8rem',
                  borderRadius: '50%'
                }}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{
                    fontSize: '50px',
                    fontWeight: '700'
                  }}
                />
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Modal  */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>Instruções para garantir a qualidade das fotos de evidências:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            {/* <p className="h4 text-center">CONTEUDO</p> */}
            <img
              src={QualidadeImagem}
              // style={{ maxHeight: '240px' }}
              className="img-fluid"
              alt="QualidadeImagem"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="ms-2 fw-semibold" width="320px" onClick={() => handleClose()}>
            FECHAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadMedia;
