import getCampoObrigatorioClassName from 'helpers/campoObrigatorio';
import React from 'react';

const Input = ({
  name,
  type = 'text',
  placeholder = '',
  label,
  noLabel = false,
  disabled = false,
  value,
  onChange,
  min,
  className = '',
  campoObrigatorio = false
}) => {
  return (
    <div className="mb-3">
      {!noLabel && (
        <label htmlFor={name} className="form-label">
          {label}
        </label>
      )}
      <input
        className={`form-control ${className} ${
          campoObrigatorio && getCampoObrigatorioClassName(value)
        }`}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        min={min}
      />
    </div>
  );
};

export default Input;
